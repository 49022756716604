.footer .ft-col-3 input[type="email"]:focus {
    border-bottom: 2px solid #b6b6b8 !important;
}

.blog-main .rt .side-box .inner select.month, .contact-1 .box .lf select {
    background: #fff;
}

@media screen and (min-width: 100px) and (max-width: 1550px) {
    .scroller {
        position: relative !important;
        padding: 40px 0 !important;
    }

    .scroller_anchor {
        height: 0px !important;
    }

    .sec-reg-1 img {
        width: 100%;
    }

}

/* ipad pro*/
@media screen and (min-width: 900px) and (max-width: 1150px) {
    header .head-top ul.cta {
        text-align: left !important;
    }

    header .head-top ul.cta li span.dt {
        font-size: 10px;
    }

    header .head-top ul.cta li a {
        font-size: 10px;
    }

    .head-top .col-md-10 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%;
    }

    .head-top .col-md-2.padright {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }

    header .head-top .top-btn button {
        font-size: 14px;
        padding: 10px 8px;
    }

    header .head-top .top-btn button.signup {
        padding: 10px 20px;
    }

    header .head-top ul.cta li {
        padding: 8px 15px;
    }

    header .head-nav .main-nav ul li {
        padding: 18px 4px;
    }

    header .head-nav .main-nav ul li a {
        font-size: 12px;
    }

    .sec-1-bg {
        height: 620px;
        background-size: 45% 85% !important;
    }

    .sec-1 .desc {
        padding: 50px 0 0;
    }

    .sec-1 .desc h2 {
        margin: 0 0 10px;
        line-height: 38px;
    }

    .sec-1 .desc h5 {
        margin: 10px 0;
        font-size: 16px;
    }

    .sec-2 .top-desc p {
        max-width: 85%;
    }

    .sec-2 .box .desc p {
        max-width: 85%;
    }

    .sec-3 a.btn-bl, .foot-cta a.btn-wt {
        padding: 15px 28px;
    }

    .sec-4 .main .desc h4 {
        font-size: 22px;
    }

    .sec-4 .main .desc p {
        font-size: 16px;
        min-height: 90px;
    }

    * body h2 {
        font-size: 34px !important;
    }

    .member-1 .outter .inner-2 .inner-row h4 sup {
        left: 50px;
    }

    .member-1 .outter .inner-1 h3 {
        font-size: 30px;
    }

    .member-1 .outter .inner-1 span {
        font-size: 18px;
    }

    .member-2 .box {
        padding: 20px 10px;
    }

    .member-2 h3 {
        font-size: 14px
    }

    .member-4 input[type="email"] {
        width: 58%;
    }

    .member-5 h2 {
        font-size: 22px !important;
    }

    .member-5 ul li a, .member-5 ul li span {
        font-size: 16px;
    }

    .member-5:before {
        right: 30px;
        top: 160px;
    }

    .prof-1 .box .inner h4 {
        font-size: 18px;
    }

    .ver-1 .box .inner h4 {
        font-size: 13px;
    }

    .contact-1 .box .rt p, .contact-1 .box .rt a {
        font-size: 18px;
        line-height: 28px;
    }

    .contact-1 .box .rt {
        padding: 60px 40px;
    }

    .reg-steps.small-set ul li, .reg-steps ul li {
        width: 19.5%;
    }

    .reg-steps.small-set ul li span, .reg-steps ul li span {
        font-size: 13px;
        width: 30px;
        height: 30px;
        padding: 4px 0 0;
        transition: all ease .2s;
    }

    .reg-steps.small-set ul li:before, .reg-steps ul li:before {
        content: '';
        position: absolute;
        width: 170px;
        height: 2px;
        top: 16px;
        background: #4e4e4e;
        right: -95px;
        z-index: -1;
        transition: all ease .2s;
    }

    .reg-steps.small-set ul li p, .reg-steps ul li p {
        font-size: 13px;
        margin: 8px 0 0;
        transition: all ease .2s;
    }

    .reg-steps.small-set ul li.done span, .reg-steps ul li.done span {
        border: 2px solid #047ec5;
        color: #fff;
        background: #047ec5;
        padding: 10px 0 0;
        transition: all ease .2s;
    }

    .sec-reg-1 form .rad-box {
        padding: 13px 10px;
    }

    header .head-info ul li.prof-opt {
        padding: 0;
    }
}

/*  tablet*/

@media screen and (min-width: 100px) and (max-width: 900px) {
    header .head-top .col-md-9 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
        order: 2;
    }

    header .head-top .col-md-3 {
        display: none;
    }

    header .head-top ul.cta li {
        border-right: 1px solid #fff;
        padding: 8px;
    }

    header .head-top ul.cta li span.dt, header .head-top ul.cta li a, header .head-top ul.cta li span.time {
        font-size: 12px;
    }

    header .head-top ul.cta li i {
        padding-right: 5px;
    }

    header .head-nav {
        padding: 10px 0;
        text-align: center;
    }

    .head-nav .col-md-9 {
        display: none;
    }

    .head-nav .col-md-3 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .sec-1 .desc {
        padding: 40px 0 0;
    }

    .sec-1 .desc h2 {
        font-size: 32px;
        line-height: 36px;
    }

    .sec-1 .desc h5 {
        margin: 10px 0;
        font-size: 14px;
    }

    .sec-1 .desc p {
        font-size: 14px;
        line-height: 18px;
    }

    .sec-1-bg {
        height: 580px;
        background-size: 45% 82% !important;
    }

    .sec-2 .top-desc p {
        font-size: 15px;
        line-height: 22px;
        max-width: initial;
        margin: 0 auto;
    }

    a.btn-wt {
        font-size: 16px;
        padding: 12px 30px;
    }

    * body h2 {
        font-size: 30px !important;
    }

    .sec-2 .box .desc p {
        font-size: 22px;
        max-width: initial;
        line-height: 30px;
    }

    .sec-3 .lf h2 {
        font-size: 23px !important;
    }

    .sec-3 .lf p {
        font-size: 20px;
    }

    .sec-3 .container {
        padding: 40px 70px;
    }

    .sec-3 a.btn-bl {
        font-size: 14px;
        padding: 12px 14px;
    }

    .sec-4 .main .desc {
        padding: 30px 20px;
    }

    .sec-4 .main .desc h4 {
        font-size: 18px;
        min-height: 40px;
    }

    .sec-4 .main .desc p {
        font-size: 16px;
        line-height: 22px;
        min-height: 80px;
    }

    .foot-cta {
        text-align: center;
    }

    .foot-cta .col-md-9 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .foot-cta .col-md-3 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .footer .ft-col-1 ul li a, .footer .ft-col-2 ul li a {
        font-size: 12px;
    }

    .footer .ft-col-2 ul li {
        margin: 0 0 0px;
    }

    .footer h3 {
        font-size: 17px;
    }

    .copy p {
        font-size: 10px;
    }

    .inner-banner {
        height: 200px;
    }

    .inner-banner h1 {
        font-size: 30px;
    }

    .inner-banner ul.bread li a {
        font-size: 15px;
    }

    .member-1 .top-desc {
        max-width: 80%;
    }

    .member-1 .outter .inner-1 h3 {
        font-size: 24px;
    }

    .member-1 .outter .inner-1 span {
        font-size: 15px;
    }

    .member-1 .outter .inner-2 .inner-row h4 {
        font-size: 24px;
    }

    .member-1 .outter .inner-2 .inner-row h4 sup {
        left: 30px;
    }

    .member-2 .container {
        padding: 60px 130px 50px;
    }

    .member-2 .col-md-4 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .member-2 .box {
        padding: 20px;
        margin-right: 0;
        margin-bottom: 1px;
    }

    .member-3 h5 {
        font-size: 16px;
    }

    .member-4 .col-md-6 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .member-4 .container {
        padding: 30px 60px;
        text-align: center;
    }

    .member-5 .container {
        width: 85%;
    }

    .member-5 ul li a, .member-5 ul li span {
        font-size: 18px;
    }

    .member-5 h2 {
        font-size: 25px !important;
    }

    .prof-1 .box .inner {
        padding: 20px 20px;
        width: 46%;
    }

    .prof-1 .box .inner h4 {
        font-size: 15px;
    }

    .prof-1 p {
        font-size: 16px;
    }

    .faq-1 .box .card-body p {
        font-size: 16px;
        line-height: 25px;
    }

    .faq-1 .box button.btn-link.collapsed, .faq-1 .box button.btn-link {
        font-size: 17px;
        line-height: 26px;
    }

    .ver-1 {
        text-align: center;
    }

    .ver-1 .col-md-2, .ver-1 .col-md-4 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .ver-1 .box .inner h4 {
        margin: 15px 0px 20px;
        font-size: 17px;
    }

    .ver-1 .box .inner input[type="number"], .ver-1 .box .inner input[type="password"] {
        margin-bottom: 20px;
    }

    .ver-1 .box .inner input[type="submit"] {
        width: auto;
    }

    .ver-1 .box p {
        margin: 20px 0;
        font-size: 20px;
        padding-bottom: 20px;
    }

    .fees-sch-1 .info-area .box-inner a,
    .fees-sch-1 .info-area .box-body ul li a.active,
    .fees-sch-1 .info-area .box-body ul li a {
        font-size: 14px;
        padding: 20px 15px 20px 40px;
    }

    .blog-main .col-md-4 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 40%;
        flex: 0 0 40%;
        max-width: 40%;
    }

    .blog-main .col-md-8 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 60%;
        flex: 0 0 60%;
        max-width: 60%;
    }

    .blog-main .lf .col-md-6 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .contact-1 .box {
        margin-top: 30px;
    }

    .contact-1 .box .lf {
        padding: 30px;
    }

    .contact-1 .box h3 {
        margin: 0 0 25px;
        font-size: 22px;
    }

    .contact-1 .box .rt {
        padding: 30px 20px;
    }

    .contact-1 .box .rt h5, .contact-1 .box .rt ul.soc h5 {
        font-size: 18px;
    }

    .contact-1 .box .rt p, .contact-1 .box .rt a {
        font-size: 16px;
        line-height: 24px;
    }

    .contact-1 .box .lf input, .contact-1 .box .lf textarea, .contact-1 .box .lf select {
        padding: 10px 22px;
        font-size: 16px;
    }

    .head-login .offset-md-6 {
        margin-left: 25%;
    }

    .head-login .col-md-3 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 35%;
        flex: 0 0 35%;
        max-width: 35%;
    }

    .sec-login .rt h2 {
        font-size: 22px !important;
    }

    .sec-login.signup .rt, .sec-login.signup .lf {
        height: 680px;
    }

    .reg-steps.small-set ul li, .reg-steps ul li {
        width: 19.5%;
    }

    .reg-steps.small-set ul li span, .reg-steps ul li span {
        color: #ffffff;
        font-size: 15px;
        font-weight: 500;
        background: #4e4e4e;
        border-radius: 100%;
        display: block;
        width: 40px;
        height: 40px;
        text-align: center;
        border: 2px solid #4e4e4e;
        margin: 0 auto;
        padding: 8px 0 0;
        transition: all ease .2s;
        z-index: 9999;
    }

    .reg-steps.small-set ul li:before, .reg-steps ul li:before {
        content: '';
        position: absolute;
        width: 120px;
        height: 2px;
        top: 16px;
        background: #4e4e4e;
        right: -65px;
        z-index: -1;
        transition: all ease .2s
    }

    .reg-steps.small-set ul li p, .reg-steps ul li p {
        font-size: 12px;
        margin: 8px 0 0;
        transition: all ease .2s;
    }

    .sec-reg-1 form .rad-box {
        padding: 13px 10px;
    }

    .reg-steps ul li.active span {
        border: 2px solid #047ec5;
        color: #047ec5;
        background: #ffffff;
    }

    .reg-steps.small-set ul li.done span, .reg-steps ul li.done span {
        border: 2px solid #047ec5;
        color: #fff;
        background: #047ec5;
        padding: 10px 0 0;
        transition: all ease .2s;
    }

    .sec-reg-1 form .col-md-6, .sec-reg-1 form .col-md-8, .sec-reg-1 form .col-sm-5,
    .sec-reg-1 form .col-md-2, .sec-reg-1 form .col-md-4, .sec-reg-1 form .col-md-3, .sec-reg-1 form .col-md-5 {
        webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .sec-reg-1 form .rad-box {
        float: none;
        text-align: left;
    }

    .sec-reg-1 form input[type="file"] {
        margin-bottom: 15px;
    }

    .sec-reg-1 form label {
        margin-bottom: 10px !important;
    }

    .sec-reg-1 form .rad-box label {
        margin-bottom: 0px !important;
    }

    .sec-reg-1 form .text-center {
        text-align: left !important;
    }
}

/*ipad 768*/
@media screen and (min-width: 750px) and (max-width: 800px) {
    .sec-1-bg {
        height: 580px;
        background-size: 45% 72% !important;
    }
}

/*landscape*/

@media screen and (min-width: 550px) and (max-width: 750px) {
    .sec-1 .desc {
        padding: 40px 200px 0 0px;
    }

    .sec-1-bg {
        height: 580px;
        background-size: 40% 60% !important;
        background-position: right 40px !important;
    }

    .sec-1 .desc h5 {
        margin: 10px 0;
        font-size: 12px;
    }

    .sec-1 .desc p {
        font-size: 12px;
        line-height: 17px;
    }

    .head-top .container {
        max-width: 720px;
    }

    .sec-3 {
        padding: 10px 0 70px;
        text-align: center;
    }

    .sec-3 .rt {
        text-align: center;
        margin: 10px 0 0;
    }

    .sec-3 .col-md-9, .sec-3 .col-md-3 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .sec-4 .main {
        margin-bottom: 30px;
    }

    .sec-4 .main .desc p {
        min-height: auto;
    }

    .copy.text-right, .copy {
        text-align: center !important;
    }

    .footer .ft-col-1 ul li {
        margin: 0 0 10px;
        display: block;
    }

    .footer .ft-col-2 {
        display: none;
    }

    .member-1 .outter .inner-2 .inner-row h4 sup {
        left: 180px;
    }

    .member-1 .outter {
        margin-bottom: 60px;
    }

    .member-2 .container {
        padding: 60px 90px 50px;
    }

    .member-3 {
        text-align: center;
    }

    .member-3 h5 {
        margin-bottom: 10px;
    }

    .prof-1 .box .inner {
        width: 100%;
        margin: 0;
    }

    .faq-1 .box {
        padding: 50px 40px;
    }

    .ver-1 .box {
        padding: 50px 40px;
    }

    .fees-sch-1 .cust .col-md-5 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 35%;
        flex: 0 0 45%;
        max-width: 45%;
    }

    .fees-sch-1 .cust .col-md-7 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 65%;
        flex: 0 0 55%;
        max-width: 55%;
    }

    .fees-sch-1 .det-area table thead {
        padding: 0 30px;
    }

    .fees-sch-1 .det-area table tbody {
        padding: 0 20px 30px;
    }

    .fees-sch-1 .info-area .box-inner a, .fees-sch-1 .info-area .box-body ul li a.active, .fees-sch-1 .info-area .box-body ul li a {
        font-size: 14px;
        padding: 20px 15px 20px 30px;
    }

    .inner-banner h1 {
        text-align: center !important;
    }

    .blog-main .container {
        max-width: 100%;
    }

    .contact-1 .box .rt ul li {
        display: block;
    }

    .contact-1 .box .rt ul.soc li {
        display: inline-block;
    }

    .sec-login .col-md-7.padleft {
        padding: 0 15px;
    }

    .sec-login .lf {
        display: none;
    }

    .sec-login .rt .inner {
        vertical-align: middle;
        display: table-cell;
        padding: 0px 15px;
        text-align: center;
    }

    .sec-login .rt .field a.rem {
        font-size: 10px;
    }

    .sec-login .rt .field a.forgot {
        font-size: 10px;
    }

    .sec-login .rt .field input {
        padding: 15px 50px;
    }

    .head-login .col-md-3, .head-login .offset-md-6 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
        margin: 0;
    }

    header.head-login .logo img {
        width: 45%;
        margin: 0 auto;
    }

    header.head-login {
        text-align: center;
    }

    header.head-login .top-btn {
        text-align: center;
        padding: 12px 0 0;
    }

    header.head-login .top-btn ul li a.login {
        color: #2083c5;
        font-size: 14px;
        font-weight: 500;
        text-transform: uppercase;
        padding: 15px 25px;
        display: inline-block;
    }

    .sec-login .rt img.logo {
        display: none;
    }

    .sec-login .rt .inner {
        vertical-align: top;
        padding: 20px 15px 0;
        text-align: center;
    }

    .sec-login .rt {
        width: 100%;
        height: auto;
    }

    .sec-login.signup .rt, .sec-login.signup .lf {
        height: auto;
    }

    .reg-steps.small-set ul li, .reg-steps ul li {
        width: 19%;
    }

    .reg-steps.small-set ul li p, .reg-steps ul li p {
        font-size: 8px;
    }

    .sec-reg-1 input[type="submit"] {
        margin: 30px 0;
    }
}


@media screen and (min-width: 550px) and (max-width: 700px) {
    .sec-1-bg {
        height: auto;
        background-image: none !important;
    }

    .sec-1 .desc {
        padding: 40px 0;
    }

    header .head-top {
        background: #047ec5;
        display: none;
    }
}

/*landscape end*/


/*mobile*/

@media screen and (min-width: 100px) and (max-width: 500px) {
    header .head-top ul.cta {
        padding: 10px 0;
    }

    header .head-top ul.cta li {
        border-right: none;
        padding: 0;
    }

    .sec-1-bg {
        height: auto;
        background-size: 45% 82% !important;
        background-image: none !important;
    }

    .sec-1 .desc {
        padding: 40px 0;
    }

    * body h2 {
        font-size: 24px !important;
        line-height: 26px !important;
    }

    .sec-2 {
        padding: 40px 0;
    }

    .sec-2 .box {
        padding: 50px 10px;
    }

    .sec-2 .box .desc p {
        font-size: 18px;
        line-height: 24px;
    }

    .sec-3 .container {
        padding: 20px;
        text-align: center;
    }

    .sec-3 .rt {
        text-align: center;
        margin: 20px 0 0;
    }

    .sec-3 .lf p {
        font-size: 16px;
        margin: 10px 0 0;
    }

    .sec-4 .top-desc h3 {
        margin: 0 0 5px;
        font-size: 20px;
    }

    .sec-4 .top-desc h2 {
        font-size: 16px !important;
    }

    .sec-4 .main .desc h4 {
        min-height: auto;
    }

    .sec-4 .main .desc p {
        min-height: auto;
    }

    .sec-4 .main {
        margin-bottom: 30px;
    }

    .foot-cta h5 {
        font-size: 18px;
    }

    .footer .ft-col-2 {
        display: none;
    }

    .footer h3 {
        margin: 0 0 20px;
        font-size: 15px;
    }

    .footer .ft-col-3 input[type="submit"] {
        top: 0;
        width: 39px;
        height: 39px;
        background-size: contain;
    }

    .footer .ft-col-1 ul {
        margin: 20px 0;
    }

    .footer .copy.text-right {
        text-align: center !important;
    }

    .inner-banner h1 {
        font-size: 24px;
    }

    .inner-banner ul.bread li a {
        font-size: 12px;
    }

    .inner-banner ul.bread li span {
        padding: 0 7px;
    }

    .inner-banner ul.bread li span i {
        font-size: 15px;
    }

    .member-1 .outter {
        margin-bottom: 70px;
    }

    .member-1 .outter .inner-2 .inner-row h4 sup {
        left: 60px;
    }

    .member-1 .top-desc {
        max-width: initial;
    }

    .member-2 .container {
        padding: 50px 30px;
    }

    .member-2 .icon img {
        width: 26px;
    }

    .member-2 h3 {
        margin: 8px 0 0px 5px;
        font-size: 14px;
    }

    .member-3 {
        padding: 40px 0;
        text-align: center;
    }

    .member-3 .row.brdr {
        padding: 15px 0;
        width: auto;
    }

    .member-3 h5 {
        font-size: 16px;
        margin-bottom: 10px;
    }

    .member-4 .container {
        padding: 30px 15px;
        text-align: center;
    }

    .member-4 h2 {
        font-size: 16px !important;
    }

    .member-4 input[type="email"] {
        width: 100%;
        float: none;
    }

    .member-4 input[type="submit"] {
        padding: 10px 20px;
        width: auto;
        float: none;
        margin: 20px 0 0;
    }

    .member-5 .container {
        width: 85%;
        padding: 30px;
    }

    .member-5 h2 {
        font-size: 18px !important;
    }

    .member-5 ul li a, .member-5 ul li span {
        font-size: 15px;
        line-height: 22px;
    }

    .member-5 ul li i.fa.fa-map-marker {
        height: 50px;
        float: left;
        top: 5px;
        position: relative;
        padding-right: 10px;
    }

    .member-5 ul li i {
        padding-right: 10px;
    }

    .member-5 ul li i.fa.fa-phone {
        position: relative;
        height: 40px;
        float: left;
        top: 5px;
    }

    .member-5:before {
        width: 220px;
        height: 190px;
        right: 10px;
    }

    .prof-1 .box {
        padding: 40px 20px;
    }

    .prof-1 .box .inner {
        padding: 20px 20px;
        width: 100%;
        margin: 0;
    }

    .faq-1 .box {
        padding: 30px;
    }

    .ver-1 .box {
        padding: 20px 10px;
    }

    .fees-sch-1 .cust .col-md-5, .fees-sch-1 .cust .col-md-7 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .fees-sch-1 .info-area {
        margin-bottom: 40px;
    }

    .fees-sch-1 .det-area table tbody {
        padding: 0 20px 30px;
    }

    .fees-sch-1 .det-area table {
        padding-bottom: 20px;
    }

    .fees-sch-1 .det-area .outter {
        padding: 25px;
    }

    .inner-banner h1 {
        text-align: center !important;
    }

    .blog-main .col-md-8 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
        order: 2;
    }

    .blog-main .col-md-4 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
        order: 1;
    }

    .blog-main .rt {
        margin-bottom: 40px;
    }

    .inner-banner p {
        font-size: 16px;
    }

    .contact-1 .box .rt ul li {
        display: block;
    }

    .contact-1 .box .rt ul.soc li {
        display: inline-block;
    }

    .contact-1 .box .rt ul.soc i {
        font-size: 18px;
    }

    .sec-login .col-md-7.padleft {
        padding: 0 15px;
    }

    .sec-login .lf {
        display: none;
    }

    .sec-login .rt .inner {
        vertical-align: middle;
        display: table-cell;
        padding: 0px 15px;
        text-align: center;
    }

    .sec-login .rt .field a.rem {
        font-size: 10px;
    }

    .sec-login .rt .field a.forgot {
        font-size: 10px;
    }

    .sec-login .rt .field input {
        padding: 15px 50px;
    }

    .head-login .col-md-3, .head-login .offset-md-6 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
        margin: 0;
    }

    header.head-login .logo img {
        width: 70%;
        margin: 0 auto;
    }

    header.head-login {
        text-align: center;
    }

    header.head-login .top-btn {
        text-align: center;
        padding: 12px 0 0;
    }

    header.head-login .top-btn ul li a.login {
        color: #2083c5;
        font-size: 14px;
        font-weight: 500;
        text-transform: uppercase;
        padding: 15px 25px;
        display: inline-block;
    }

    .sec-login .rt img.logo {
        display: none;
    }

    .sec-login .rt .inner {
        vertical-align: top;
        padding: 20px 15px 0;
        text-align: center;
    }

    .sec-login .rt {
        width: 100%;
        height: auto;
    }

    .sec-login.signup .rt, .sec-login.signup .lf {
        height: auto;
    }

    .footer .ft-col-1 ul li {
        margin: 0 0 10px;
        display: block;
    }

    .copy {
        text-align: center;
    }

    .ver-1:before {
        display: none;
    }

    header .head-top {
        display: none;
    }

    .reg-steps.small-set ul li, .reg-steps ul li {
        width: 55%;
        display: block;
        margin: 0 auto;
        padding-bottom: 30px;
    }

    .reg-steps.small-set ul li:before, .reg-steps ul li:before {
        content: '';
        position: absolute;
        width: 2px;
        height: 30px;
        top: 66px;
        background: #4e4e4e;
        right: 0;
        z-index: -1;
        transition: all ease .2s;
        left: 0;
        margin: 0 auto;
    }

    .sec-reg-1 input[type="submit"] {
        margin: 30px 0;
        padding: 12px 45px;
    }
}


@media screen and (min-width: 360px) and (max-width: 500px) {
    .member-5 ul li i.fa.fa-phone {
        position: relative;
        height: auto;
        float: left;
        top: 5px;
    }

    .member-1 .outter .inner-2 .inner-row h4 sup {
        left: 100px;
    }
}

li.current-page a {
    color: #3f7ec6;
}