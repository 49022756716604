/*LOGO CSS*/
html {
    scroll-behavior: smooth;
}

a {
    text-decoration: none !important;
    text-transform: capitalize;
}

h1, h2, h3, h4, h5, h6 {
    text-transform: capitalize;
}

h1, h2, h3 {
    font-weight: bold;
}

.animatedSwitch {
    position: relative;
}


.shine-me {
    width: 100%; /*Make sure the animation is over the whole element*/
    -webkit-animation-name: ShineAnimation;
    -webkit-animation-duration: 1.8s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: ease;
}

@-webkit-keyframes ShineAnimation {
    from {
        background-repeat: no-repeat;
        background-image: -webkit-linear-gradient(
                top left,
                rgba(255, 255, 255, 0.0) 0%,
                rgba(255, 255, 255, 0.0) 45%,
                rgba(255, 255, 255, 0.5) 48%,
                rgba(255, 255, 255, 0.8) 50%,
                rgba(255, 255, 255, 0.5) 52%,
                rgba(255, 255, 255, 0.0) 57%,
                rgba(255, 255, 255, 0.0) 100%
        );
        background-position: -250px -250px;
        background-size: 500px 600px
    }
    to {
        background-repeat: no-repeat;
        background-position: 250px 250px;
    }
}

div.shimmer-div {
    /*margin: 10px;*/
    margin: 0;
    padding: 0;
}

.media-block-div {
    display: flex;
    width: initial;
}

.text-line {
    max-height: 50%;
    height: 1em;
    background-color: rgb(205, 205, 205);
    margin-top: 0px;
}

.shimmer-button {
    max-height: 100%;
    width: 41%;
    height: 2.5em;
    background-color: rgb(205, 205, 205);
    margin-top: 0px;
    border-radius: 5px;
}

.round-image-shimmer {
    background-color: rgb(205, 205, 205);
    width: 80px;
    height: 100%;
    min-height: 80px;
    min-width: 80px;
    margin-right: 10px;
    border-radius: 500rem;
}

.square-image-shimmer {
    max-height: 100%;
    width: 100px;
    height: 4em;
    background-color: rgb(205, 205, 205);
    margin-top: 0px;
    border-radius: 10px;
}