/* custom.css */
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i|Poppins:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&display=swap');

body, html {
    font-family: 'Poppins', sans-serif !important;
}

body {
    background: #fff;
}

.nopad {
    padding: 0;
}

.padleft {
    padding-left: 0;
}

.padright {
    padding-right: 0;
}

a:hover, a:active {
    text-decoration: none !important;
}

* :focus {
    outline: 0px auto -webkit-focus-ring-color !important;
    outline-offset: 0 !important;
    text-decoration: none !important;
}

* a:hover {
    text-decoration: none !important;
}

ul {
    padding: 0;
    margin: 0;
}

ul li {
    list-style: none;
    display: inline-block;
}

#return-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background: #057cc2;
    width: 50px;
    height: 50px;
    display: block;
    text-decoration: none;
    -webkit-border-radius: 35px;
    -moz-border-radius: 35px;
    border-radius: 35px;
    display: none;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    z-index: 9999;
    line-height: 50px;
    text-align: center;
    color: #fff;
    font-size: 25px;
}

#return-to-top:hover {
    background: rgba(0, 0, 0, 0.9);
}

#return-to-top:hover i {
    color: #fff;
    top: 5px;
}


/* new css start 13-04-2020 */

.nav1 {
    display: none;
}

nav.nav1 .logo {
    text-align: center;
    margin: 20px 0 30px;
}

nav.nav1 .logo img {
    max-width: 80%;
    margin: 0 auto;
}

/*----------------------------------------Push Navigation---------------------------------*/
div#menuoverlay {
    display: none;
}

a#hamburg {
    position: fixed;
    top: 0px;
    left: 10px;
    width: 40px;
    height: 40px;
    z-index: 9;
    text-align: center;
    color: white;
    display: none;
    transition: all ease-out 0.3s;
}

a#hamburg i {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
}

.nav1 {
    transition: all ease-out 0.3s;
    display: none;
}

.nav1 a {
    color: #000;
}

.nav1 ul {
    margin: 0;
    padding: 0;
    background-color: #303030;
}

.nav1 ul li {
    display: inline-block;
    position: relative;
    padding: 10px 20px !important;
}

.nav1 ul li > a {
    display: inline-block;
    padding: 10px;
}

.nav1 ul li > a > i {
    margin-left: 15px;
    transition: all ease-out 0.3s;
    -webkit-transition: all ease-out 0.1s;
}

.nav1 ul li ul {
    display: none;
    position: absolute;
    top: 38px;
    background: #222;
    width: 200px;
    text-align: left;
}

.nav1 .search-nav, .nav1 .title {
    display: none;
}

.nav1 ul li ul li {
    display: block;
}

.nav1 ul li ul li a {
    display: block;
}

.nav1 ul li:hover > a {
    color: #000;
    text-decoration: none;
}

.nav1 ul li:hover > a > i {
    transform: rotateZ(90deg);
    -webkit-transform: rotateZ(90deg);
}

.nav1 ul li:hover ul {
    display: block;
}

.nav1 span.marrow {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #fff;
    float: right;
    height: 0;
    margin: 8px 0 0 8px;
    width: 0;
}

.nav1 ul li.open span.marrow {
    border-left: 5px solid transparent;
    border-top: 0;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #fff;
}

@media screen and (max-width: 1024px) {

    .slider .item .carousel-caption {
        top: 80px;
    }

    .slider-text h3 {
        font-size: 40px;
    }

    .slider-text p {
        font-size: 16px;
        margin-bottom: 25px;
    }

    .slider-text .form-group label {
        font-size: 12px;
    }

}

.open-menu .nav-cross:before {
    content: "x";
    position: absolute;
    z-index: -1;
    font-size: 22px;
    background: #3f7ec6;
    font-weight: 500;
    width: 33px;
    height: 33px;
    top: 10px;
    left: 0;
}


@media screen and (max-width: 900px) {
    nav.nav1 {
        display: block;
    }

    .logo-bar nav {
        display: none !important;
    }

    a#hamburg {
        display: block;
    }

    main {
        transition: all ease-out 0.3s;
    }

    .nav1 .row {
        margin: 0px;
    }

    .nav1 {
        background-color: #fff;
        box-shadow: -2px 0 10px #999 inset;
        display: block;
        height: 100%;
        left: 0;
        overflow-x: hidden;
        overflow-y: auto;
        position: fixed;
        top: 0;
        transform: translateX(-250px);
        -webkit-transform: translateX(-250px);
        width: 250px;
    }

    .nav1 .search-nav, .nav1 .title {
        display: block;
    }

    .nav1 .search-nav input {
        border: 1px solid #2c313f;
        background-color: rgba(255, 255, 255, 0.2);
        padding: 5px;
        width: 100%;
        color: #000;
        margin: 10px 0;
    }

    .nav1 .search-nav input[type="submit"] {
        background: url("../images/search-icon.png") no-repeat scroll center center;
        border: 0;
        width: auto;
        font-size: 0;
        height: 16px;
        position: absolute;
        right: 7px;
        top: 8px;
        width: 16px;
        filter: invert(100%);
    }

    .nav1 .title {
        background: #000;
        color: #fff;
        font-size: 14px;
        margin: 0 -15px;
        padding: 6px 15px 6px;
    }

    .search-nav > form {
        position: relative;
    }

    .nav1 ul {
        text-align: left;
        background-color: transparent;
    }

    .nav1 ul li {
        display: block;
        border-bottom: 1px solid #999;
    }

    .nav1 ul li a {
        display: block;
        padding: 0;
    }

    .nav1 ul li:hover > a {
        color: #000;
    }

    .nav1 ul li a > i {
        float: right;
    }

    .nav1 ul li ul {
        display: none;
        position: static;
        width: 100%;
        background-color: transparent;
        padding: 0 10px;
    }

    .nav1 ul li:hover > ul {
        display: none;
    }

    .nav1 ul li:hover > a > i {
        transform: rotateZ(0);
        -webkit-transform: rotateZ(0);
    }

    .nav1 ul li.open > a {
        background-color: transparent;
    }

    .nav1 ul li.open > a > i {
        transform: rotateZ(90deg);
        -webkit-transform: rotateZ(90deg);
    }

    .nav1 ul li.open > ul {
        display: block;
    }

    div#menuoverlay {
        display: block;
        visibility: hidden;
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        transition: all ease-out 0.3s;
        z-index: 1;
        opacity: 0;
    }

    html.open-menu {
        overflow: hidden;
    }

    html.open-menu div#menuoverlay {
        visibility: visible;
        background: rgba(0, 0, 0, 0.5);
        min-height: 100vh;
        width: 100% !important;
        opacity: 1;
        width: calc(-150%);
        left: 250px;
    }

    html.open-menu a#hamburg {
        transform: translateX(250px);
        -webkit-transform: translateX(250px);
        z-index: 999;
    }

    html.open-menu main {
        transform: translateX(250px);
        -webkit-transform: translateX(250px);
    }

    html.open-menu nav.nav1 {
        z-index: 99999;
        transform: translateX(0);
        -webkit-transform: translateX(0);
    }

    .menu-main-menu-container ul ul ul li a {
        padding: 5px;
    }

    ul li.has-subnav .accordion-btn {
        color: #000;
        font-size: 24px;
        width: 20px !important;
        float: right;
        line-height: inherit !important;
    }

    .nav1 ul li:last-child {
        border: 0;
    }

    .nav-cross.open > span {
        background: #fff;
    }

    /*---------------------Hamberg Icon --------------------------------------------- */
    .nav-cross {
        width: 24px;
        height: 40px;
        position: relative;
        margin: 15px auto;
        transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        transition: .5s ease-in-out;
        cursor: pointer;
    }

    .nav-cross span {
        display: block;
        position: absolute;
        height: 2px;
        width: 100%;
        background: #000;
        opacity: 1;
        left: 0;
        transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        transition: .25s ease-in-out;
    }

    .open-menu .nav-cross span {
        display: none;
    }

    #nav-icon1 span:nth-child(1) {
        top: 0px;
    }

    #nav-icon1 span:nth-child(2) {
        top: 18px;
    }

    #nav-icon1 span:nth-child(3) {
        top: 36px;
    }

    #nav-icon1.open span:nth-child(1) {
        top: 18px;
        transform: rotate(135deg);
        -webkit-transform: rotate(135deg);
    }

    #nav-icon1.open span:nth-child(2) {
        opacity: 0;
        left: -60px;
    }

    #nav-icon1.open span:nth-child(3) {
        top: 18px;
        transform: rotate(-135deg);
        -webkit-transform: rotate(-135deg);
    }

    .nav-cross span:nth-child(1) {
        top: 12px;
        transform-origin: left center;
        -webkit-transform-origin: left center;
    }

    .nav-cross span:nth-child(2) {
        top: 20px;
        transform-origin: left center;
        -webkit-transform-origin: left center;
    }

    .nav-cross span:nth-child(3) {
        top: 28px;
        transform-origin: left center;
        -webkit-transform-origin: left center;
    }

    .nav-cross.open span:nth-child(1) {
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        top: 11px;
        left: 4px;
    }

    .nav-cross.open span:nth-child(2) {
        width: 0%;
        opacity: 0;
    }

    .nav-cross.open span:nth-child(3) {
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        top: 28px;
        left: 4px;
    }
}

/* new css ends */


/*Scroll css Start here*/


body::-webkit-scrollbar {
    width: 8px;
}

body::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 30px #000;
}

body::-webkit-scrollbar-thumb {
    background-color: #0576c2;
    outline: 1px solid #0576c2;
}

/*Scroll css End here*/

/*btn*/

a.btn-wt {
    color: #0069b2;
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    padding: 14px 40px;
    background: #fff;
    -webkit-box-shadow: 0px 4px 5px 1px rgba(87, 168, 215, 0.5);
    -moz-box-shadow: 0px 4px 5px 1px rgba(87, 168, 215, 0.5);
    box-shadow: 0px 4px 5px 1px rgba(87, 168, 215, 0.5);
    display: inline-block;
    transition: all ease-out .5s;
}

a.btn-wt:hover {
    background: #0069b2;
    color: #fff;
    transition: all ease-out .5s;
}

a.btn-bl {
    color: #fff;
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    padding: 14px 40px;
    background: #0069b2;
    -webkit-box-shadow: 0px 4px 5px 1px rgba(87, 168, 215, 0.5);
    -moz-box-shadow: 0px 4px 5px 1px rgba(87, 168, 215, 0.5);
    box-shadow: 0px 4px 5px 1px rgba(87, 168, 215, 0.5);
    display: inline-block;
    transition: all ease-out .5s;
}

a.btn-bl:hover {
    background: #fff;
    color: #0069b2;
    transition: all ease-out .5s;
}


/*header*/

header {

}

header .head-top {
    background: #047ec5;
}

header .head-top ul.cta {

}

header .head-top ul.cta li {
    border-right: 1px solid #fff;
    padding: 13px 15px;
}

header .head-top ul.cta li:last-child {
    padding-right: 0;
    border-right: none;
}

header .head-top ul.cta li i {
    font-size: 15px;
    color: #fff;
    padding-right: 12px;
    vertical-align: middle;
}

header .head-top ul.cta li span.dt {
    color: #ffffff;
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
    vertical-align: middle;
}

header .head-top ul.cta li span.time {
    color: #ffffff;
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
    vertical-align: middle;
    padding-left: 5px;
}

header .head-top ul.cta li a {
    color: #ffffff;
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
    vertical-align: middle;
    text-transform: lowercase;
}

header .head-top .top-btn {

}

.sec-1 .sec-1-bg {
    background: url(../images/main-img.jpg) no-repeat right;
}


header .head-top .top-btn button a {
    color: #f9f9f9;
}

header .head-top .top-btn button {
    color: #f9f9f9;
    font-size: 14px;
    font-weight: 400;
    background: transparent;
    border: none;
    padding: 14px 15px;
    text-transform: uppercase;
    margin: 0;
    cursor: pointer;
}

header .head-top .top-btn button.signup {
    font-weight: 600;
    background: #4e4e4e;
    padding: 14px 20px;
}

header .head-nav {
    padding: 8px 0;
}

header .head-nav .logo {
    margin: 10px 0 0;
}

header .head-nav .logo img {
    max-width: 220px;
    height: auto;
}

header .head-nav .main-nav {

}

header .head-nav .main-nav ul {

}

header .head-nav .main-nav ul li {
    padding: 20px 5px;
}


header .head-nav .main-nav ul li.nav-item.active a.nav-link.active {
    color: #047ec5;
}

header .head-nav .main-nav ul li:last-child {
    padding-right: 0;
}

header .head-nav .main-nav ul li a {
    color: #4e4e4e;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    padding: 0 !important;
}


/*sec-1*/

.sec-1 {
    background: rgba(7, 128, 199, 1);
    background: -moz-linear-gradient(top, rgba(7, 128, 199, 1) 0%, rgba(123, 198, 240, 1) 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(7, 128, 199, 1)), color-stop(100%, rgba(123, 198, 240, 1)));
    background: -webkit-linear-gradient(top, rgba(7, 128, 199, 1) 0%, rgba(123, 198, 240, 1) 100%);
    background: -o-linear-gradient(top, rgba(7, 128, 199, 1) 0%, rgba(123, 198, 240, 1) 100%);
    background: -ms-linear-gradient(top, rgba(7, 128, 199, 1) 0%, rgba(123, 198, 240, 1) 100%);
    background: linear-gradient(to bottom, rgba(7, 128, 199, 1) 0%, rgba(123, 198, 240, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0780c7', endColorstr='#7bc6f0', GradientType=0);
}

.sec-1-bg {
    background: url("../images/main-img.jpg") right;
    background-repeat: no-repeat;
    height: auto;
    padding-bottom: 30px;
    background-size: 45% 96% !important;
}

.sec-1 .col-md-6 {

}

.sec-1 .desc {
    padding: 80px 0 0;
}

.sec-1 .desc h2 {
    margin: 0 0 10px;
    color: #fff;
    font-size: 45px;
    font-weight: 700;
    line-height: 45px;
}

.sec-1 .desc h5 {
    margin: 20px 0;
    color: #fff;
    font-weight: 700;
    font-size: 16px;
}

.sec-1 .desc p {
    color: #fff;
    font-size: 16px;
    line-height: 22px;
    font-weight: 500;
}

.sec-1 .desc a {
    margin: 5px 0 0;
}


/*sec-2*/

.sec-2 {
    padding: 70px 0 60px;
}

.sec-2 .top-desc {
    margin: 0 0 100px;
}

.sec-2 .top-desc h2 {
    margin: 0px 0 15px;
    color: #027dc3;
    font-size: 40px;
    font-weight: 600;
}

.sec-2 .top-desc p {
    color: #606060;
    font-size: 17px;
    font-weight: 400;
    line-height: 24px;
    max-width: 75%;
    margin: 0 auto;
}

.sec-2 .box {
    background: #0b7fd2;
    border-radius: 5px;
    padding: 50px;
    position: relative;
    width: 90%;
    margin: 0 auto;
}

.sec-2 .box .thumb {
    position: absolute;
    top: -70px;
    left: 0;
    right: 0;
}

.sec-2 .box .thumb img {

}

.sec-2 .box .desc {
    margin-top: 70px;
}

.sec-2 .box .desc p {
    color: #fff;
    font-size: 24px;
    font-weight: 600;
    max-width: 80%;
    margin: 0 auto;
    line-height: 36px;
}

.sec-2 .box .desc .rating {
    margin: 30px 0 18px;
}

.sec-2 .box .desc h3 {
    margin: 0 0 6px;
    color: #fff;
    font-size: 21px;
    font-weight: 700;
}

.sec-2 .box .desc h6 {
    margin: 0;
    color: #fff;
    font-weight: 500;
    font-size: 14px;
}


/*sec-3*/

.sec-3 {
    padding: 10px 0 70px;
}

.sec-3 .container {
    background: #ffffff;
    -webkit-box-shadow: 0px 0px 27px 8px rgba(0, 0, 0, 0.08);
    -moz-box-shadow: 0px 0px 27px 8px rgba(0, 0, 0, 0.08);
    box-shadow: 0px 0px 27px 8px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    padding: 60px 70px 50px;
}

.sec-3 .lf {

}

.sec-3 .lf h2 {
    margin: 0;
    color: #4e4e4e;
    font-size: 40px;
    font-weight: 500;
    text-transform: capitalize;
}

.sec-3 .lf p {
    color: #0b7fd2;
    font-size: 24px;
    font-weight: 500;
    margin: 0;
}

.sec-3 .rt {
    text-align: right;
}

.sec-3 a {

}


/*sec-4*/

.sec-4 {
    background: url(../images/work-bg.jpg) repeat;
    padding: 70px 0;
}

.sec-4 .top-desc {
    margin-bottom: 60px;
}

.sec-4 .top-desc h3 {
    margin: 0 0 12px;
    color: #0096e7;
    font-size: 22px;
    text-transform: uppercase;
    font-weight: 500;
}

.sec-4 .top-desc h2 {
    margin: 0;
    color: #4e4e4e;
    font-size: 40px;
    font-weight: 500;
    text-transform: capitalize;
}

.sec-4 .main {
    -webkit-box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.06);
    -moz-box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.06);
    box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.06);
    border-radius: 18px;
}

.sec-4 .main .thumb {

}

.sec-4 .main .thumb img {
    width: 100%;
}

.sec-4 .main .desc {
    background: #fff;
    border: 1px solid #d1d1d1;
    border-bottom-left-radius: 18px;
    border-bottom-right-radius: 18px;
    padding: 40px 30px 50px;
}

.sec-4 .main .desc h4 {
    margin: 0 0 15px;
    color: #000000;
    font-size: 25px;
    font-weight: 400;
}

.sec-4 .main .desc p {
    color: #666666;
    font-size: 18px;
    font-weight: 300;
    line-height: 27px;
}

.sec-4 .main .desc a {
    margin: 10px 0 0;
    font-size: 15px;
    font-weight: 500;
    padding: 10px 25px;
}


/*foot-cta*/
.foot-cta {
    background: #0576c2;
    padding: 60px 0;
    position: relative;
}

.foot-cta:before {
    content: '';
    position: absolute;
    right: 0;
    top: 30px;
    background: url(../images/foot-cta-be.png) no-repeat;
    width: 974px;
    height: 110px;
}

.foot-cta h2 {
    margin: 0;
    color: #ffffff;
    font-size: 40px;
    font-weight: 500;
}

.foot-cta h5 {
    margin: 12px 0;
    color: #ffffff;
    font-weight: 300;
    font-size: 20px;
}

.foot-cta p {
    margin: 0;
    color: #ffffff;
    font-size: 15px;
    font-weight: 400;
}

.foot-cta .rt {
    margin: 30px 0 0;
}

.footer {
    background: #363636;
    padding: 60px 0 0;
    color: rgba(255, 255, 255, 0.75);
}

.footer h3 {
    margin: 0 0 30px;
    color: #ffffff;
    font-size: 20px;
    font-weight: 600;
    text-transform: capitalize;
}

.footer .ft-col-1 {

}

.footer .ft-col-1 ul {
    margin: 20px 0 0;
}

.footer .ft-col-1 ul li {
    margin: 0 0 10px;
}

.footer .ft-col-1 ul li a {
    color: rgba(255, 255, 255, 0.75);
    font-size: 14px;
    font-weight: 400;
    text-transform: lowercase;
}

.footer .ft-col-1 ul li i {
    padding-right: 10px;
}

.footer .ft-col-2 ul {

}

.footer .ft-col-2 ul li {
    margin: 0 0 8px;
    display: block;
}

.footer .ft-col-2 ul li a {
    color: rgba(255, 255, 255, 0.75);
    font-size: 15px;
    font-weight: 400;
}

.footer .ft-col-3 {

}

.footer .ft-col-3 .newslt {
    position: relative;
}

.footer .ft-col-3 input[type="email"] {
    font-size: 12px;
    font-weight: 400;
    border: none;
    border-bottom: 2px solid #b6b6b8;
    width: 100%;
    background: transparent;
    padding: 12px 0 15px;
    color: #fff;
}

.footer .ft-col-3 input[type="email"]::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: rgba(255, 255, 255, 0.75);
}

.footer .ft-col-3 input[type="email"]::-moz-placeholder { /* Firefox 19+ */
    color: rgba(255, 255, 255, 0.75);
}

.footer .ft-col-3 input[type="email"]:-ms-input-placeholder { /* IE 10+ */
    color: rgba(255, 255, 255, 0.75);
}

.footer .ft-col-3 input[type="email"]:-moz-placeholder { /* Firefox 18- */
    color: rgba(255, 255, 255, 0.75);
}

.footer .ft-col-3 input[type="submit"] {
    position: absolute;
    top: -10px;
    right: 0;
    background: url(../images/sub-btn.png) no-repeat;
    font-size: 0;
    border: none;
    width: 49px;
    height: 49px;
}

.footer .ft-col-3 ul.soc {
    margin: 20px 0 0;
}

.footer .ft-col-3 ul.soc li {
    padding-right: 12px;
}

.footer .ft-col-3 ul.soc li a i {
    color: #cccccc;
    font-size: 20px;
}

.footer .brdr {
    margin-top: 30px;
}

.footer .brdr {
    border-top: 1px solid #505050;
    padding: 15px 0;
}

.copy p {
    font-size: 12px;
    font-weight: 400;
    margin: 0;
}


/*inner-banner*/

.inner-banner {
    background: url("../images/contact-banner.jpg") no-repeat;
    height: 300px;
    display: table;
    width: 100%;
    text-align: center;
    background-size: cover !important;
}

.inner-banner .container {
    height: 100%;
    display: table-cell;
    vertical-align: middle;
}

.inner-banner h1 {
    color: #ffffff;
    font-weight: 700;
    font-size: 45px;
    text-transform: uppercase;
}

.inner-banner p {
    color: #ffffff;
    font-size: 22px;
    font-weight: 300;
}

.inner-banner ul.bread {

}

.inner-banner ul.bread li {

}

.inner-banner ul.bread li a {
    color: #ffffff;
    font-weight: 600;
    font-size: 19px;
}

.inner-banner ul.bread li span {
    padding: 0 10px;
}

.inner-banner ul.bread li span i {
    color: #fff;
    font-size: 20px;
}


/*member-1*/
.member-1 {
    /*background: #f6f6f6;*/
    padding: 70px 0 90px;
}

.member-1.mem-det {
    padding-top: 0px;
}

.member-1 .top-desc {
    max-width: 50%;
    margin: 0 auto 90px;
}

.member-1 .top-desc h2 {
    margin: 0 0 15px;
    color: #333333;
    font-size: 40px;
    text-transform: uppercase;
    font-weight: 600;
}

.member-1 .top-desc p {
    margin: 0;
    color: #666666;
    font-size: 15px;
    font-weight: 400;
}


.member-1 .outter {
    background: #ffffff;
    padding: 30px 0;
    position: relative;
    border-radius: 10px;
    box-shadow: 0px 0px 17px 8px rgba(0, 0, 0, 0.05);
}

.member-1 .outter .inner-1 {
    padding: 50px 0 30px;
    border-bottom: 2px solid #40a9e7;
}

.member-1 .outter .inner-1 .thumb {
    background: #008cdf;
    border-radius: 100%;
    position: absolute;
    width: 80px;
    height: 80px;
    padding: 14px 0 0;
    top: -40px;
    border: 1px solid #fff;
    left: 0;
    right: 0;
    margin: 0 auto;
}

.member-1 .outter .inner-1 .thumb img {

}

.member-1 .outter .inner-1 h3 {
    color: #333333;
    font-size: 37px;
    font-weight: 500;
    margin: 0px 0 5px;
}

.member-1 .outter .inner-1 span {
    color: #666666;
    font-size: 20px;
    font-weight: 300;
}

.member-1 .outter .inner-2 {

}

.member-1 .outter .inner-2 .inner-row {
    border-bottom: 1px solid #ebebeb;
    padding: 15px 15px;
}

.member-1 .outter .inner-2 .inner-row:last-child {
    border-bottom: none;
}

.member-1 .outter .inner-2 .inner-row h4 {
    margin: 0 0 3px;
    color: #008cdf;
    font-size: 35px;
    font-weight: 500;
    position: relative;
}

.member-1 .outter .inner-2 .inner-row h4 sup {
    color: #787878;
    font-size: 18px;
    font-weight: 500;
    position: absolute;
    top: 10px;
    left: 85px;
}

.member-1 .outter .inner-2 .inner-row h6 {
    color: #787878;
    font-size: 15px;
    font-weight: 400;
    margin: 0;
}

.member-1 .outter .inner-2 .inner-row h6 span {
    font-size: 12px;
}

.member-1 .outter a {
    margin: 10px 0 20px;
    color: #ffffff;
    font-size: 17px;
    font-weight: 500;
    box-shadow: none;
    padding: 12px 35px;
}

.member-1 .outter a:hover {
    background: #0069b2;
    color: #fff;
}

/*member-2*/

.member-2 {
    padding: 0 0 60px;
}

.member-2 .container {
    background: #ffffff;
    -webkit-box-shadow: 0px 0px 27px 8px rgba(0, 0, 0, 0.08);
    -moz-box-shadow: 0px 0px 27px 8px rgba(0, 0, 0, 0.08);
    box-shadow: 0px 0px 27px 8px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    padding: 60px 70px 50px;
    margin-top: -30px;
}

.member-2 .box {
    background: #0069b2;
    overflow: hidden;
    padding: 32px 20px;
    border-radius: 5px;
    margin-right: 1px;
}

.member-2 .col-md-4:nth-child(2) .box {
    background: #008cdf;
}

.member-2 .icon {
    float: left;
    width: 14%;
}

.member-2 .icon img {
    width: 39px;
}

.member-2 h3 {
    margin: 12px 0 0px 10px;
    color: #ffffff;
    font-size: 16px;
    font-weight: 600;
    float: left;
    text-transform: uppercase;
}


/*member-3*/

.member-3 {
    background: url("../images/mem-3-bg.jpg") no-repeat;
    padding: 80px 0;
    background-size: cover !important;
}

.member-3 .top-desc {
    margin: 0 0 60px;
}

.member-3 .top-desc h2 {
    margin: 0 0 15px;
    color: #ffffff;
    font-size: 40px;
    text-transform: capitalize;
    font-weight: 600;
}

.member-3 .row.brdr {
    border-bottom: 1px solid #4096d1;
    padding: 25px 0;
    width: 85%;
    margin: 0 auto;
}

.member-3 .row.brdr:last-child {
    border: none;
}

.member-3 h5 {
    margin: 0;
    color: #ffffff;
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 600;
}

.member-3 p {
    color: #fff;
    font-size: 14px;
    line-height: 17px;
    font-weight: 400;
    margin: 0;
}

/*member-4*/

.member-4 {
    padding: 80px 0 0;
}

.member-4 .container {
    background: #0069b2;
    border-radius: 10px;
    padding: 60px 70px 50px;
}

.member-4 h2 {
    color: #ffffff;
    font-size: 40px;
    text-transform: capitalize;
    font-weight: 600;
}

.member-4 .rt {
    padding-top: 15px;
}

.member-4 input[type="email"] {
    color: #ffffff;
    font-size: 15px;
    text-transform: uppercase;
    font-weight: 500;
    border: 1px solid #fff;
    background: transparent;
    padding: 12px 10px;
    width: 60%;
    float: left;
}

.member-4 input[type="email"]::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #fff;
}

.member-4 input[type="email"]::-moz-placeholder { /* Firefox 19+ */
    color: #fff;
}

.member-4 input[type="email"]:-ms-input-placeholder { /* IE 10+ */
    color: #fff;
}

.member-4 input[type="email"]:-moz-placeholder { /* Firefox 18- */
    color: #fff;
}

.member-4 input[type="submit"] {
    color: #0069b2;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 500;
    border: none;
    background: #fff;
    padding: 10px;
    width: 38%;
    float: left;
    cursor: pointer;
    margin: 0 0 0 8px;
}


/*member-5*/

.member-5 {
    padding: 70px 0;
    position: relative;
}

.member-5:before {
    content: '';
    position: absolute;
    background: url(../images/mem-mail-icon.png) no-repeat;
    width: 350px;
    height: 190px;
    right: 70px;
    top: 210px;
    background-size: contain;
}

.member-5 .container {
    background: #ffffff;
    -webkit-box-shadow: 0px 0px 27px 5px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0px 0px 27px 5px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 0px 27px 5px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    padding: 60px 70px 50px;
    width: 65%;
    margin: 0 auto;
}

.member-5 h2 {
    margin: 0 0 30px;
    color: #027dc3;
    font-weight: 600;
    position: relative;
    padding-bottom: 20px;
    font-size: 34px;
}

.member-5 h2:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    background: #027dc3;
    width: 70px;
    height: 2px;
}

.member-5 ul {

}

.member-5 ul li {
    display: block;
    margin-bottom: 7px;
}

.member-5 ul li:first-child {
    margin-bottom: 20px;
}

.member-5 ul li i {
    color: #606060;
    padding-right: 20px;
    font-size: 19px
}

.member-5 ul li span {
    color: #606060;
    font-size: 22px;
    font-weight: 500;
}

.member-5 ul li a {
    color: #606060;
    font-size: 22px;
    font-weight: 500;
}


/*prof-1*/

.prof-1 {
    padding: 60px 0;
}

.prof-1 h2 {
    margin: 0 0 20px;
    color: #027dc3;
    font-weight: 600;
    position: relative;
    padding-bottom: 15px;
    font-size: 40px;
    display: inline-block;
}

.prof-1 h2:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    background: #027dc3;
    width: 100%;
    height: 1px;
}

.prof-1 p {
    color: #4e4e4e;
    font-size: 20px;
    font-weight: 400;
    margin: 0 0 40px;
}

.prof-1 .box {
    background: #ffffff;
    -webkit-box-shadow: 0px 0px 27px 5px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0px 0px 27px 5px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 0px 27px 5px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    padding: 80px 70px;
    margin: 0 auto;
}

.prof-1 .box .inner {
    display: inline-block;
    position: relative;
    background: #fff;
    -webkit-box-shadow: 0px 0px 13px 3px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0px 0px 13px 3px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 0px 13px 3px rgba(0, 0, 0, 0.05);
    padding: 20px 40px;
    margin-right: 15px;
    width: 40%;
}

.prof-1 .box .inner .icon {
    float: left;
    width: 15%;
}

.prof-1 .box .inner .icon img {
    height: 28px;
}

.prof-1 .box .inner h4 {
    margin: 4px 0 0;
    color: #0187d4;
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 600;
    float: left;
}


/*faq-1*/

.faq-1 {
    padding: 70px 0;
}

.faq-1 h2 {
    margin: 0 0 40px;
    color: #027DC3;
    font-weight: 600;
    position: relative;
    padding-bottom: 15px;
    font-size: 40px;
}

.faq-1 .box {
    background: #FFFFFF;
    -webkit-box-shadow: 0px 0px 27px 5px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0px 0px 27px 5px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 0px 27px 5px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    padding: 60px 70px 50px;
}

.faq-1 .box .card {
    border: 0;
    border-bottom: 1px solid #d8efff;
    border-radius: 0;
}

.faq-1 .box button.btn-link {
    padding: 0;
    color: #0576c2;
    font-size: 19px;
    text-transform: uppercase;
    font-weight: 500;
    border: none;
    text-align: left;
    background: transparent;
    display: block;
    width: 100%;
}

.faq-1 .box button.btn-link:hover {
    text-decoration: none !important;
}

.faq-1 .box button.btn-link.collapsed {
    font-weight: 500;
    color: #333333 !important;
    font-size: 19px;
    line-height: 32px;
    padding: 0;
}

.faq-1 .box .card-header {
    background: transparent;
    border: none;
    border-radius: 0;
    padding: 15px 0;
}

.faq-1 .box .card-body {
    padding: 10px 0 0;
}

.faq-1 .box .card-body p {
    color: #4b4d5c;
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
}

/*faq-2*/

.faq-2 {
    padding: 30px 0;
}

.faq-2 .desc {
    padding: 0 30px;
}

.faq-2 .desc p {
    color: #666666;
    font-size: 15px;
    font-weight: 400;
    line-height: 27px;
}

/*ver-1*/


.ver-1 {
    padding: 70px 0;
    position: relative;
}

.ver-1:before {
    content: '';
    position: absolute;
    background: url(../images/verify-icon-bg.png) no-repeat;
    width: 541px;
    height: 576px;
    top: 50px;
    left: 0;
    background-size: contain;
    z-index: 9;
}

.ver-1 .box {
    background: #FFFFFF;
    -webkit-box-shadow: 0px 0px 27px 5px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0px 0px 27px 5px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 0px 27px 5px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    padding: 50px 70px;
}

.ver-1 .box img {
    display: inline-block;
}

.ver-1 .box h2 {
    margin: 0;
    display: inline-block;
    color: #027dc3;
    font-weight: 600;
    position: relative;
    padding-bottom: 15px;
    font-size: 35px;
    vertical-align: bottom;
}

.ver-1 .box h2:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    background: #027dc3;
    width: 100%;
    height: 1px;
}

.ver-1 .box p {
    margin: 30px 0 40px;
    color: #4e4e4e;
    font-size: 25px;
    font-weight: 500;
    border-bottom: 1px solid #d8efff;
    padding-bottom: 25px;
}

.ver-1 .box .inner {

}

.ver-1 .box .inner h4 {
    margin: 15px 0 0;
    color: #4e4e4e;
    font-size: 17px;
    font-weight: 500;
    vertical-align: middle;
}

.ver-1 .box .inner input[type="number"], .ver-1 .box .inner input[type="password"], #phone-num {
    width: 100%;
    padding: 12px 25px;
    color: #8e8e8e;
    border: 1px solid #b9b9b9;
}

.ver-1 .box .inner input[type="submit"] {
    color: #ffffff;
    font-size: 14px;
    font-weight: 500;
    box-shadow: none;
    padding: 14px 18px;
    -webkit-box-shadow: 0px 4px 5px 1px rgba(87, 168, 215, 0.5);
    -moz-box-shadow: 0px 4px 5px 1px rgba(87, 168, 215, 0.5);
    box-shadow: 0px 4px 5px 1px rgba(87, 168, 215, 0.5);
    transition: all ease-out .5s;
    background: #0069b2;
    border: none;
    width: 100%;
}

.ver-1 .box .inner input[type="submit"]:hover {
    background: #0069b2;
    color: #fff;
    cursor: pointer;
}

/*contact-1*/

.contact-1 {

}

.contact-1 .box {
    background: #FFFFFF;
    -webkit-box-shadow: 0px 0px 27px 5px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0px 0px 27px 5px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 0px 27px 5px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    margin-top: -60px;
}

.contact-1 .box h3 {
    margin: 0 0 35px;
    color: #4e4e4e;
    font-size: 32px;
    font-weight: 500;
}

.contact-1 .box .lf {
    padding: 60px;
    background: #fff;
}

.contact-1 .box .lf input, .contact-1 .box .lf textarea, .contact-1 .box .lf select {
    width: 100%;
    border: 1px solid #cdd1d7;
    padding: 15px 22px;
    border-radius: 5px;
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: 300;
    resize: none;
    color: #2c4059;
}

.contact-1 .box .lf textarea {
    height: 120px;
}

.contact-1 .box .lf input:focus, .contact-1 .box .lf textarea:focus, .contact-1 .box .lf select:focus {
    border: 1px solid #73beea;
}

.contact-1 .box .lf input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #2c4059;
}

.contact-1 .box .lf input::-moz-placeholder { /* Firefox 19+ */
    color: #2c4059;
}

.contact-1 .box .lf input:-ms-input-placeholder { /* IE 10+ */
    color: #2c4059;
}

.contact-1 .box .lf input:-moz-placeholder { /* Firefox 18- */
    color: #2c4059;
}

.contact-1 .box .lf select::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #2c4059;
}

.contact-1 .box .lf select::-moz-placeholder { /* Firefox 19+ */
    color: #2c4059;
}

.contact-1 .box .lf select:-ms-input-placeholder { /* IE 10+ */
    color: #2c4059;
}

.contact-1 .box .lf select:-moz-placeholder { /* Firefox 18- */
    color: #2c4059;
}

.contact-1 .box .lf textarea::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #2c4059;
}

.contact-1 .box .lf textarea::-moz-placeholder { /* Firefox 19+ */
    color: #2c4059;
}

.contact-1 .box .lf textarea:-ms-input-placeholder { /* IE 10+ */
    color: #2c4059;
}

.contact-1 .box .lf textarea:-moz-placeholder { /* Firefox 18- */
    color: #2c4059;
}

.contact-1 .box .lf input[type="submit"] {
    color: #fff;
    text-transform: uppercase;
    font-size: 22px;
    font-weight: 500;
    width: auto;
    padding: 10px 50px;
    background: #0069b2;
    -webkit-box-shadow: 0px 4px 5px 1px rgba(87, 168, 215, 0.5);
    -moz-box-shadow: 0px 4px 5px 1px rgba(87, 168, 215, 0.5);
    box-shadow: 0px 4px 5px 1px rgba(87, 168, 215, 0.5);
    transition: all ease-out .5s;
    cursor: pointer;
}


.contact-1 .box .rt {
    background: #fafbfc;
    padding: 60px 50px;
}

.contact-1 .box .rt ul li {
    margin-bottom: 25px;
}

.contact-1 .box .rt h5 {
    margin: 0 0 12px;
    color: #4e4e4e;
    font-size: 24px;
    font-weight: 500;
}

.contact-1 .box .rt p, .contact-1 .box .rt a {
    color: #2c4059;
    font-size: 20px;
    font-weight: 300;
    line-height: 32px;
    margin: 0;
}

.contact-1 .box .rt ul.soc {
    margin: 30px 0 0;
}

.contact-1 .box .rt ul.soc h5 {
    margin: 0 0 12px;
    color: #4e4e4e;
    font-size: 24px;
    font-weight: 500;
}

.contact-1 .box .rt ul.soc li {
    margin-right: 15px;
}

.contact-1 .box .rt ul.soc i {
    color: #939daa;
    font-size: 24px;
}


/*fees-schedules css start*/

.fees-sch-1 {
    padding: 70px 0;
}

.fees-sch-1 .top-desc {
    margin-bottom: 40px;
}

.fees-sch-1 .top-desc h2 {
    margin: 0;
    color: #333333;
    font-size: 40px;
    text-transform: uppercase;
    font-weight: 600;
}

.fees-sch-1 .cust .col-md-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 35%;
    flex: 0 0 35%;
    max-width: 35%;
}

.fees-sch-1 .cust .col-md-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 65%;
    flex: 0 0 65%;
    max-width: 65%;
}

.fees-sch-1 .info-area {
    border: 2px solid #e1e2e4;
    background: #fff;
    border-radius: 5px;
    background: #fff;
    -webkit-box-shadow: 0px 0px 27px 5px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0px 0px 27px 5px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 9px 17px 0px rgba(0, 0, 0, 0.05);
}

.fees-sch-1 .info-area .box-inner {

}

.fees-sch-1 .info-area .box-inner a {
    color: #ffffff;
    font-size: 20px;
    font-weight: 500;
    background: #2485be;
    display: block;
    padding: 20px 15px 20px 60px;
}

.fees-sch-1 .info-area .box-inner a i {
    transform: rotate(90deg) translateY(15px);
    vertical-align: middle;
}

.fees-sch-1 .info-area .box-inner a.collapsed i {
    transform: rotate(0deg) translateY(0px) translateX(-15px);
    vertical-align: middle;
}

.fees-sch-1 .info-area .box-body {

}

.fees-sch-1 .info-area .box-body ul {

}

.fees-sch-1 .info-area .box-body ul li {
    display: block;
    width: 100%;
    padding: 10px 0;
}

.fees-sch-1 .info-area .box-body ul li:last-child {
    padding-bottom: 0;
}

.fees-sch-1 .info-area .box-body ul li:last-child a {
    border-bottom: none;
}

.fees-sch-1 .info-area .box-body ul li a {
    display: block;
    color: #4f4f4f;
    font-weight: 400;
    font-size: 18px;
    padding: 15px 30px 25px 60px;
    border-bottom: 1px solid #ececec;
    background: #fff;
    z-index: 999;
    position: relative;
}

.fees-sch-1 .info-area .box-body ul li a.active {
    -webkit-box-shadow: 0px 5px 7px 0px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0px 5px 7px 0px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 5px 7px 0px rgba(0, 0, 0, 0.05);
    color: #2485be;
    border-radius: 0 0px 12px 13px;
    font-weight: 500;
}


.fees-sch-1 .det-area {
    border: 2px solid #e1e2e4;
    background: #fff;
    border-radius: 5px;
    background: #fff;
    -webkit-box-shadow: 0px 0px 27px 5px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0px 0px 27px 5px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 9px 17px 0px rgba(0, 0, 0, 0.05);
}

.fees-sch-1 .det-area table {
    width: 100%;
    display: block;
    padding-bottom: 80px;
    border-bottom: 1px solid #ebebeb;
}

.fees-sch-1 .det-area table thead {
    background: #ffff;
    width: 100%;
    display: block;
    -webkit-box-shadow: 0px 5px 7px 0px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0px 5px 7px 0px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 5px 12px 0px rgba(0, 0, 0, 0.05);
    padding: 0 40px;
    margin-bottom: 20px;
    border-bottom: 1px solid #eff0f5;
}

.fees-sch-1 .det-area table thead tr {
    width: 100%;
    display: flex;
}

.fees-sch-1 .det-area table thead tr th:first-child {
    width: 80%;
}

.fees-sch-1 .det-area table thead tr th:last-child {
    width: 20%;
}

.fees-sch-1 .det-area table thead th {
    color: #2485be;
    font-size: 17px;
    font-weight: 500;
    padding: 23px 0px 23px 0;
}

.fees-sch-1 .det-area table tbody {
    width: 100%;
    display: block;
    padding: 0 40px 30px;
}

.fees-sch-1 .det-area table tbody tr {
    border-bottom: 1px solid #eff0f5;
    width: 100%;
    display: flex;
}

.fees-sch-1 .det-area table tbody tr:last-child {
    border-bottom: none;
}

.fees-sch-1 .det-area table tbody tr td:first-child {
    width: 80%;
}

.fees-sch-1 .det-area table tbody tr td:last-child {
    width: 20%;
}

.fees-sch-1 .det-area table td {
    color: #4f4f4f;
    font-size: 15px;
    font-weight: 300;
    padding: 12px 10px;
}

.fees-sch-1 .det-area table td strong {
    font-weight: 500;
}

.fees-sch-1 .det-area .outter {
    padding: 40px;
}

.fees-sch-1 .det-area .outter p {
    color: #666666;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    margin: 0
}

/*fees-schedules css end*/

/*Banner background Images*/
.inner-banner-contact-us-bg {
    background: url("../images/contact-banner.jpg") no-repeat;
}

.inner-banner-membership-info-bg {
    background: url("../images/member-ban.jpg") no-repeat;
}

.inner-banner-professional-bg {
    background: url("../images/professional-banner.jpg") no-repeat;
}

.inner-banner-faq-bg {
    background: url("../images/faq-banner.jpg") no-repeat;
}

.inner-banner-eligibility-bg {
    background: url("../images/eligiblity-banner.jpg") no-repeat;
}

.inner-banner-fee-schedule-bg {
    background: url("../images/fee-banner.jpg") no-repeat;
}


/*blog-main*/

.blog-main {
    background: #f9f9fb;
    padding: 80px 0;
}

.blog-main .lf {

}

.blog-main .lf .box {
    margin-bottom: 30px;
    transition: all ease 0.5s;
}

.blog-main .lf .box:hover {
    box-shadow: 0px 0px 27px 0px rgba(3, 125, 196, 0.3);
    transition: all ease 0.5s;
}

.blog-main .lf .box .pic {

}

.blog-main .lf .box .pic img {
    width: 100%;
}

.blog-main .lf .box .desc {
    background: #fff;
    padding: 30px 20px;
}

.blog-main .lf .box .desc h3 {
    margin: 0 0 20px;
    color: #000000;
    font-size: 24px;
    font-weight: 500;
    font-family: Gilroy-SemiBold;
    line-height: 30px;
    position: relative;
    padding-bottom: 20px;
}

.blog-main .lf .box .desc h3:before {
    content: '';
    position: absolute;
    left: 0;
    background: #037dc4;
    bottom: 0;
    width: 50px;
    height: 3px;
    box-shadow: 0px 0px 7px 0px rgba(3, 125, 196, 0.5);
}

.blog-main .lf .box .desc p {
    color: #282526;
    font-size: 15px;
    font-family: Gilroy-Regular;
    font-weight: 500;
    line-height: 22px;
}

.blog-main .lf .box .desc h6 {
    margin: 0;
    color: #666666;
    font-weight: 400;
    font-size: 14px;
}

.blog-main .lf .box .desc h6 i {
    padding-right: 3px;
}

.blog-main .rt {

}

.blog-main .rt .search {
    overflow: hidden;
}

.blog-main .rt .search input {
    width: 65%;
    color: #606062;
    font-size: 15px;
    font-weight: 400;
    padding: 15px;
    background: #fff;
    float: left;
    border: none;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.blog-main .rt .search input[type="submit"] {
    background: #057cc5;
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    width: 35%;
    float: left;
    border-radius: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    cursor: pointer;
}

.blog-main .rt .side-box {
    margin-top: 25px;
    box-shadow: 0px 0px 27px 5px rgba(0, 0, 0, 0.05);
}

.blog-main .rt .side-box .upper {
    background: #2485be;
    padding: 15px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.blog-main .rt .side-box .upper h3 {
    margin: 0;
    color: #fff;
    font-weight: 600;
    font-size: 20px;
    text-transform: capitalize;
}

.blog-main .rt .side-box .inner {
    background: #fff;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 20px;
}

.blog-main .rt .side-box .inner .inner-row {
    border-bottom: 1px solid #ebebeb;
    padding-bottom: 15px;
    margin-bottom: 15px;
}

.blog-main .rt .side-box .inner .inner-row:last-child {
    border: none;
    padding: 0;
}

.blog-main .rt .side-box .inner .inner-row h4 {
    margin: 0 0 5px;
    color: #333333;
    font-size: 15px;
    font-weight: 500;
}

.blog-main .rt .side-box .inner .inner-row h5 {
    margin: 0;
    color: #4f4f4f;
    font-weight: 300;
    font-size: 12px;
}

.blog-main .rt .side-box .inner select.month {
    width: 100%;
    padding: 2px 10px;
    border: none;
    color: #606062;
    font-size: 16px;
    font-weight: 400;
}

.blog-main .rt .side-box .inner .select_box {
    width: 100%;
    overflow: hidden;
    position: relative;
}

.blog-main .rt .side-box .inner .select_box:after {
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid #2485be;
    position: absolute;
    top: 39%;
    right: 3px;
    content: "";
    z-index: 98;
}

.blog-main .rt .side-box .inner ul.meta {

}

.blog-main .rt .side-box .inner ul.meta li {
    display: block;
    border-bottom: 1px solid #ebebeb;
    padding: 0px 20px 12px 20px;
    margin-bottom: 12px;
}

.blog-main .rt .side-box .inner ul.meta li a {
    color: #4f4f4f;
    font-weight: 400;
    font-size: 15px;
}

.blog-main .rt .side-box .inner ul.meta li:last-child {
    margin: 0;
    border: none;
}

.blog-main .rt .side-box-2 {
    background: #fff;
    border-radius: 5px;
    padding: 20px;
    margin-top: 20px;
    box-shadow: 0px 0px 27px 5px rgba(0, 0, 0, 0.05);
}

.blog-main .rt .side-box-2 .upper {

}

.blog-main .rt .side-box-2 .upper h3 {
    color: #333333;
    font-size: 20px;
    font-weight: 600;
    border-bottom: 1px solid #e1e2e4;
    margin-bottom: 20px;
    padding-bottom: 20px;
}

.blog-main .rt .side-box-2 .inner {

}

.blog-main .rt .side-box-2 .inner .box {
    margin-bottom: 30px;
}

.blog-main .rt .side-box-2 .inner .box .pic {

}

.blog-main .rt .side-box-2 .inner .box .pic img {
    width: 100%;
    border-radius: 20px;
}

.blog-main .rt .side-box-2 .inner .box .desc {

}

.blog-main .rt .side-box-2 .inner .box .desc h3 {
    color: #202020;
    font-size: 15px;
    font-weight: 500;
    margin: 12px 0 20px;
}

.blog-main .rt .side-box-2 .inner .box .desc h6 {
    color: #acacac;
    font-size: 12px;
    font-weight: 400;
}

.blog-det {

}

.blog-det .lf {

}

.blog-det .lf h1 {
    margin-bottom: 20px;
    color: #000000;
    font-size: 40px;
    font-weight: 500;
}

.blog-det .lf img {
    width: 100%;
    margin: 20px auto;
}

.blog-det .lf p {
    color: #282526;
    font-size: 15px;
    font-weight: 300;
    line-height: 27px;
}

.blog-det .lf ul {

}

.blog-det .lf ul li {
    margin-right: 20px;
}

.blog-det .lf span {
    color: #666666;
    font-size: 14px;
}

.blog-det .lf span i {
    padding-right: 5px;
}

.blog-det .lf .meta {
    margin: 0px 0 20px;
    border-bottom: 1px solid #e1e2e4;
    padding-bottom: 10px;
}

.blog-det .lf h2 {
    margin-bottom: 20px;
    color: #000000;
    font-size: 40px;
    font-weight: 500;
    border-bottom: 1px solid #e1e2e4;
    padding-bottom: 15px;
}


/*login*/


header.head-login {
    -webkit-box-shadow: 0px 0px 27px 8px rgba(0, 0, 0, 0.08);
    -moz-box-shadow: 0px 0px 27px 8px rgba(0, 0, 0, 0.08);
    box-shadow: 0px 0px 27px 8px rgba(0, 0, 0, 0.08);
    position: relative;
    z-index: 9;
    padding: 15px 0 !important;
}

header.head-login .logo img {
    width: 100%;
}

header.head-login .top-btn {
    text-align: right;
    padding: 12px 0 0;
}

header.head-login .top-btn ul {

}

header.head-login .top-btn ul li {

}

header.head-login .top-btn ul li a.login {
    color: #2083c5;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    padding: 15px 25px;
}

header.head-login .top-btn ul li a.signup {
    color: #2083c5;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    padding: 15px 25px;
}

header.head-login .top-btn ul li a.active {
    background: #2083c5;
    color: #fff !important;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    padding: 15px 25px;
}

/*sec-login*/


.sec-login {
    background: #f7f8f9;
    padding: 60px 0 0;
}

.sec-login .lf {
    width: 100%;
    height: 600px;

}

.sec-login .lf img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.sec-login .rt {
    background: #fff;
    display: table;
    width: 100%;
    height: 600px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.sec-login .rt .inner {
    vertical-align: middle;
    display: table-cell;
    padding: 0px 70px;
}

.sec-login .rt img.logo {
    width: 100%;
    max-width: 200px;
}

.sec-login .rt h2 {
    margin: 15px 0;
    color: #1b1d1f;
    font-size: 22px;
    font-weight: 500;
}

.sec-login .rt .field {
    position: relative;
    margin-bottom: 15px;
    overflow: hidden;
}

.sec-login .rt .field:before {
    content: '';
    position: absolute;
    left: 25px;
    top: 33%;
}

.sec-login .rt .field.user:before {
    background: url(../images/user.png) no-repeat;
    height: 15px;
    width: 15px;
}

.sec-login .rt .field.pass:before {
    background: url(../images/password.png) no-repeat;
    width: 15px;
    height: 19px;
}

.sec-login .rt .field.email:before {
    background: url(../images/mail.png) no-repeat;
    width: 18px;
    height: 13px;
    top: 40%;
}

.sec-login .rt .field input {
    width: 100%;
    border: 1px solid #e1e2e4;
    border-radius: 8px;
    padding: 18px 50px;
}

.sec-login .rt .field input[type="submit"] {
    width: auto;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    text-transform: capitalize;
    padding: 14px 70px;
    background: #057cc5;
    -webkit-box-shadow: 0px 4px 5px 1px rgba(87, 168, 215, 0.5);
    -moz-box-shadow: 0px 4px 5px 1px rgba(87, 168, 215, 0.5);
    box-shadow: 0px 4px 5px 1px rgba(87, 168, 215, 0.5);
    display: inline-block;
    transition: all ease-out .5s;
    cursor: pointer;
    margin: 10px 0;
    border-radius: 5px;
}

.sec-login .rt .field input[type="submit"]:hover {
    background: #fff;
    color: #057cc5;
    transition: all ease-out .5s;
}

.sec-login .rt .field a.rem {
    color: #7b8b9d;
    font-size: 12px;
    font-weight: 400;
    float: left;
}

.sec-login .rt .field a.rem img {
    padding-right: 10px;
}

.sec-login .rt .field a.forgot {
    color: #7b8b9d;
    font-size: 12px;
    font-weight: 400;
    float: right;
}

.sec-login .rt .field p {
    color: #666666;
    font-size: 13px;
    font-weight: 400;
}

.sec-login .rt .field p a {
    color: #0276c2;
    font-weight: 500;
    font-size: 13px;
    padding-left: 15px;
}

.sec-login.signup {
    padding: 60px 0;
}

.sec-login.signup .lf {
    height: 750px;
}

.sec-login.signup .rt {
    height: 750px;
}


/*register css (26-3-2020)*/

.reg-steps {
    padding: 60px 0;
    transition: all ease .2s;
}

.reg-steps.small-set {
    transition: all ease .2s;
}

.reg-steps ul {

}

.reg-steps ul li {
    width: 19.5%;
    position: relative;
    transition: all ease .2s;
}

.reg-steps.small-set ul li {
    width: 15%;
    transition: all ease .2s;
}

.reg-steps.small-set ul li:before {
    content: '';
    position: absolute;
    width: 170px;
    height: 2px;
    top: 16px;
    background: #4e4e4e;
    right: -95px;
    z-index: -1;
    transition: all ease .2s;
}

.reg-steps ul li:before {
    content: '';
    position: absolute;
    width: 185px;
    height: 2px;
    top: 19px;
    background: #4e4e4e;
    right: -95px;
    z-index: 99;
    transition: all ease .2s;
}

.reg-steps ul li.active:before, .reg-steps ul li.done:before {
    background: #047ec5;
}

.reg-steps ul li:last-child:before {
    display: none;
}

.reg-steps ul li span {
    color: #ffffff;
    font-size: 15px;
    font-weight: 500;
    background: #4e4e4e;
    border-radius: 100%;
    display: block;
    width: 40px;
    height: 40px;
    text-align: center;
    border: 2px solid #4e4e4e;
    margin: 0 auto;
    padding: 8px 0 0;
    transition: all ease .2s;
    z-index: 9999;
}

.reg-steps.small-set ul li span {
    font-size: 13px;
    width: 30px;
    height: 30px;
    padding: 4px 0 0;
    transition: all ease .2s;
}

.reg-steps ul li p {
    color: #4e4e4e;
    font-size: 15px;
    font-weight: 500;
    margin: 12px 0 0;
    transition: all ease .2s;
}

.reg-steps.small-set ul li p {
    font-size: 13px;
    margin: 8px 0 0;
    transition: all ease .2s;
}

.reg-steps ul li.active span {
    border: 2px solid #047ec5;
    color: #047ec5;
    background: #ffffff;
}

.reg-steps ul li.active p {
    color: #047ec5
}

.reg-steps ul li.done span {
    border: 2px solid #047ec5;
    color: #fff;
    background: #047ec5;
    padding: 10px 0 0;
    transition: all ease .2s;
}

.reg-steps.small-set ul li.done span {
    padding: 7px 0 0;
    transition: all ease .2s;
}

.reg-steps ul li.done p {
    color: #047ec5
}


.scroller_anchor {
    height: 0px;
}

.scroller {
    background: #FFF;
    border: none;
    margin: 0 auto 10px;
    z-index: 100;
    height: auto;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    width: 100%;
    transition: all ease .2s;
}

.sec-reg-1 {
    padding: 80px 0;
    background: #f6f6f6;
}

.sec-reg-1 form.brdr {
    border-bottom: 2px solid #abaaaa;
    padding-bottom: 40px;
}

.sec-reg-1 form .def {
    width: 100%;
    background: #fff;
    border: 1px solid #e1e2e4;
    border-radius: 7px;
    padding: 13px;
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 15px;
}

.sec-reg-1 form label {
    color: #666666;
    font-size: 14px;
    font-weight: 400;
}


.sec-reg-1 form .mrgn-top {
    margin-top: 20px;
    margin-bottom: 0;
}

.sec-reg-1 form .mrgn-btm {
    margin-bottom: 15px;
}

.sec-reg-1 form label.main {
    font-size: 15px;
    display: block;
}

.sec-reg-1 form label.mrgn-top-10 {
    margin: 10px 0 0;
}

.sec-reg-1 form .terms {
    background: #f1f1f1;
    padding: 25px;
}

.sec-reg-1 form .terms .lin-22 {
    line-height: 22px;
}

.sec-reg-1 form .brdr {
    background: #d0d0d0;
    height: 2px;
    margin: 40px 0 20px;
}

.sec-reg-1 form .rad-box {
    background: #fff;
    border: 1px solid #e1e2e4;
    border-radius: 7px;
    padding: 13px 20px;
    width: auto;
    float: right;
}

.sec-reg-1 form .rad-box label {
    padding-top: 4px;
}

.sec-reg-1 form .rad-box .custom-control-inline:last-child {
    margin-right: 0;
}

.sec-reg-1 form select {
    color: #989898;
}

.sec-reg-1 form input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #989898;
}

.sec-reg-1 form input::-moz-placeholder { /* Firefox 19+ */
    color: #989898;
}

.sec-reg-1 form input:-ms-input-placeholder { /* IE 10+ */
    color: #989898;
}

.sec-reg-1 form input:-moz-placeholder { /* Firefox 18- */
    color: #989898;
}

.sec-reg-1 form textarea::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #989898;
}

.sec-reg-1 form textarea::-moz-placeholder { /* Firefox 19+ */
    color: #989898;
}

.sec-reg-1 form textarea:-ms-input-placeholder { /* IE 10+ */
    color: #989898;
}

.sec-reg-1 form textarea:-moz-placeholder { /* Firefox 18- */
    color: #989898;
}

.sec-reg-1 form label.check-clr {
    color: #989898;
}

.sec-reg-1 .upload-area {
    background: #efeeee;
    padding: 25px 15px;
    margin-top: 40px;
}

.sec-reg-1 .upload-area h5 {
    color: #666666;
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
    margin: 0 0 20px;
}

.sec-reg-1 .upload-area .ar-col {
    background: #fff;
    border: 1px solid #e1e2e4;
    border-radius: 7px;
    padding: 13px;
    margin-bottom: 20px;
}

.sec-reg-1 .upload-area .ar-col p {
    margin: 0;
    color: #666666;
    font-size: 13px;
    font-weight: 500;
}

/*RegistrationBanner Background*/
.dentist-registration-banner {
    background: rgb(0, 121, 190);
    background: linear-gradient(0deg, rgba(0, 121, 190, 1) 16%, rgba(0, 94, 149, 1) 72%);
}

header {
    padding: 0 !important;
}

header .head-info ul li.prof-opt a.dropbtn {
    color: white;
}

.sweet-loading {
    padding: 20px 0px;
}

.sweet-loading > div {
    border-color: #4A90E2;
    display: block;
    margin: 0 auto;
}

#return-to-top {
    background: #4A90E2 !important;
}

header .head-info ul li.prof-opt .dropdown {
    position: relative;
    display: inline-block;
    top: 5px;
    left: 10px;
}

header .head-info ul li.prof-opt a {
    font-size: 12px !important;
}

header .head-info ul li.prof-opt .image-box {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    float: left;
}

header .head-info ul li.prof-opt {
    padding: 4px 0 0;
}

.scroller.start {
    position: fixed !important;
    top: 0;
    padding: 20px 0 !important;
    transition: all ease .2s;
}

@media screen and (min-width: 100px) and (max-width: 1550px) {
    .scroller.start {
        position: relative !important;
        padding: 40px 0 !important;
    }
}


.secure-desc {
    padding: 70px 0;
    /*background: #f6f6f6;*/
}

.secure-desc h2 {
    margin: 0px 0 35px;
    color: #027dc3;
    font-size: 40px;
    font-weight: 600;
    text-align: center;
}

.secure-desc h4 {
    color: #4e4e4e;
    font-size: 25px;
    font-weight: 500;
}

.secure-desc h5 {
    color: #4e4e4e;
    font-size: 20px;
    font-weight: 500;
}

.secure-desc ul {
    padding: 0;
    margin: 0px 0 40px;
}

.secure-desc ul li {
    list-style: none;
    display: block;
    margin: 10px 0;
}

.secure-desc ul li i {
    color: #000;
    font-size: 20px;
    vertical-align: middle;
    margin-right: 10px;
}

.secure-desc ul li a {
    color: #2c4059;
    font-size: 16px;
    font-weight: 500;
    vertical-align: middle;
}

.secure-desc h3 {
    margin: 0 0 20px;
    letter-spacing: 2px;
    color: #4e4e4e;
    font-size: 28px;
    font-weight: 500;
}

.secure-desc .desc p {
    color: #666666;
    font-size: 15px;
    font-weight: 400;
    line-height: 27px;
}

.secure-desc ul.points li i {
    color: #027dc3;
    font-size: 18px;
    vertical-align: middle;
    margin-right: 10px;
    float: left;
    height: 40px;
    position: relative;
    top: 5px;
}

.fees-sch-1 .info-area .box-body ul li a span {
    display: block;
    font-size: 12px;
}

/* privacy policy */
.secure-desc.privacy-policy {
    padding: 70px 0;
    background: white;
}

.secure-desc.privacy-policy h2 {
    margin: 0px 0 35px;
    color: #027dc3;
    font-size: 40px;
    font-weight: 600;
    text-align: center;
}

.secure-desc.privacy-policy h4 {
    color: #4e4e4e;
    font-size: 25px;
    font-weight: 500;
}

.secure-desc.privacy-policy h5 {
    color: #4e4e4e;
    font-size: 20px;
    font-weight: 500;
}

.secure-desc.privacy-policy ul {
    padding: 0;
    margin: 0px 0 40px;
}

.secure-desc.privacy-policy ul li {
    list-style: none;
    display: block;
    margin: 10px 0;
}

.secure-desc.privacy-policy ul li i {
    color: #000;
    font-size: 20px;
    vertical-align: middle;
    margin-right: 10px;
}

.secure-desc.privacy-policy ul li a {
    color: #2c4059;
    font-size: 16px;
    font-weight: 500;
    vertical-align: middle;
}

.secure-desc.privacy-policy h3 {
    margin: 0 0 20px;
    letter-spacing: 2px;
    color: #4e4e4e;
    font-size: 28px;
    font-weight: 500;
}

.secure-desc.privacy-policy .desc p {
    color: #666666;
    font-size: 15px;
    font-weight: 400;
    line-height: 27px;
}

.secure-desc.privacy-policy ul.points li i {
    color: #027dc3;
    font-size: 18px;
    vertical-align: middle;
    margin-right: 10px;
    float: left;
    height: 40px;
    position: relative;
    top: 5px;
}

/* privacy policy end */

/* example of savings */
.sec-savings table {
    margin: 50px 0 0;
    width: 100%;
    display: block;
}

.sec-savings table thead {
    background: #f6f7ff;
    border-radius: 8px;
    width: 100%;
    display: block;
}

.sec-savings table thead th {
    color: #333333;
    font-size: 17px;
    font-weight: 400;
    padding: 12px 30px;
}

.sec-savings table td,
.sec-savings table th {
    width: 21%;
}

.sec-savings table td:first-child,
.sec-savings table th:first-child {
    width: 50%;
}

.sec-savings table tbody {
    height: 500px;
    overflow-x: hidden;
    float: left;
    width: 100%;
}

.sec-savings table tbody::-webkit-scrollbar {
    width: 5px;

}

.sec-savings table tbody::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 30px #f6f7ff;
    border-radius: 10px;
}

.sec-savings table tbody::-webkit-scrollbar-thumb {
    background-color: #5477f2;
    outline: 1px solid #5477f2;
    border-radius: 10px;
}

.sec-savings table tbody tr {
    border-bottom: 1px solid #eff0f5;
}

.sec-savings table td {
    color: #39414e;
    font-size: 15px;
    font-weight: 400;
    padding: 20px 15px 20px 30px;
}

.secure-desc.example-sav {
    padding: 70px 0;
    background: white;
}

.secure-desc.example-sav h2 {
    margin: 0px 0 35px;
    color: #027dc3;
    font-size: 40px;
    font-weight: 600;
    text-align: center;
}

.secure-desc.example-sav h4 {
    color: #4e4e4e;
    font-size: 25px;
    font-weight: 500;
}

.secure-desc.example-sav h5 {
    color: #4e4e4e;
    font-size: 20px;
    font-weight: 500;
}

.secure-desc.example-sav ul {
    padding: 0;
    margin: 0px 0 40px;
}

.secure-desc.example-sav ul li {
    list-style: none;
    display: block;
    margin: 10px 0;
}

.secure-desc.example-sav ul li i {
    color: #000;
    font-size: 20px;
    vertical-align: middle;
    margin-right: 10px;
}

.secure-desc.example-sav ul li a {
    color: #2c4059;
    font-size: 16px;
    font-weight: 500;
    vertical-align: middle;
}

.secure-desc.example-sav h3 {
    margin: 0 0 20px;
    letter-spacing: 2px;
    color: #4e4e4e;
    font-size: 28px;
    font-weight: 500;
}

.secure-desc.example-sav .desc p {
    color: #666666;
    font-size: 15px;
    font-weight: 400;
    line-height: 27px;
}

.secure-desc.example-sav ul.points li i {
    color: #027dc3;
    font-size: 18px;
    vertical-align: middle;
    margin-right: 10px;
    float: left;
    height: 40px;
    position: relative;
    top: 5px;
}

.secure-desc.example-sav .desc p a {
    font-weight: 600;
    color: #007bff;
}

.secure-desc.example-sav .desc p a:hover, .secure-desc .desc p a:focus {
    color: #007bff;
}

/* example of savings end */

/* terms and conditions */

.secure-desc.terms-cond {
    padding: 70px 0;
    background: white;
}

.secure-desc.terms-cond h2 {
    margin: 0px 0 35px;
    color: #027dc3;
    font-size: 40px;
    font-weight: 600;
    text-align: center;
}

.secure-desc.terms-cond h4 {
    color: #4e4e4e;
    font-size: 25px;
    font-weight: 500;
}

.secure-desc.terms-cond h5 {
    color: #4e4e4e;
    font-size: 20px;
    font-weight: 500;
}

.secure-desc.terms-cond ul {
    padding: 0;
    margin: 0px 0 40px;
}

.secure-desc.terms-cond ul li {
    list-style: none;
    display: block;
    margin: 10px 0;
}

.secure-desc.terms-cond ul li i {
    color: #000;
    font-size: 20px;
    vertical-align: middle;
    margin-right: 10px;
}

.secure-desc.terms-cond ul li a {
    color: #2c4059;
    font-size: 16px;
    font-weight: 500;
    vertical-align: middle;
}

.secure-desc.terms-cond h3 {
    margin: 0 0 20px;
    letter-spacing: 2px;
    color: #4e4e4e;
    font-size: 28px;
    font-weight: 500;
}

.secure-desc.terms-cond .desc p {
    color: #666666;
    font-size: 15px;
    font-weight: 400;
    line-height: 27px;
}

.secure-desc.terms-cond ul.points li i {
    color: #027dc3;
    font-size: 18px;
    vertical-align: middle;
    margin-right: 10px;
    float: left;
    height: 40px;
    position: relative;
    top: 5px;
}

/* terms and condtions end */

/* payment page css */

.sec-payment {
    padding: 60px 0;
}

.sec-payment h5 {
    font-size: 20px;
    color: #3c79be;
    margin: 0 0 20px;
}

.sec-payment h4 {
    font-weight: 500;
    color: #333333;
    font-size: 25px;
    line-height: 32px;
    padding: 0;
    margin: 0 0 20px;
}

.sec-payment .def {
    width: 100%;
    background: #fff;
    border: 1px solid #e1e2e4;
    border-radius: 7px;
    padding: 13px;
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 15px;
}

.sec-payment label {
    font-size: 14px;
    display: block;
}

.sec-payment label.sm {
    display: inline-block;
    font-style: italic;
    color: #3c79be;
}

.sec-payment input:focus, .sec-payment select:focus {
    border: 1px solid #e1e2e4 !important;
}

.sec-payment img.pad-15 {
    padding: 25px 0;
}

.sec-payment .mrgn-top {
    margin-top: 30px;
}

.sec-payment input[type="radio"] {
    margin-right: 5px;
}

.sec-payment label.sm-b {
    display: inline-block;
    margin-right: 10px;
    vertical-align: middle;
    font-size: 14px;
    margin-bottom: 20px;
}

.sec-payment input[type="submit"] {
    color: #fff;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 500;
    width: auto;
    padding: 15px 50px;
    background: #0069b2;
    -webkit-box-shadow: 0px 4px 5px 1px rgba(87, 168, 215, 0.5);
    -moz-box-shadow: 0px 4px 5px 1px rgba(87, 168, 215, 0.5);
    box-shadow: 0px 4px 5px 1px rgba(87, 168, 215, 0.5);
    transition: all ease-out .5s;
    cursor: pointer;
    border: none;
}

.sec-payment input[type="submit"]:focus {
    border: none !important;
    -webkit-box-shadow: 0px 4px 5px 1px rgba(87, 168, 215, 0.5);
    -moz-box-shadow: 0px 4px 5px 1px rgba(87, 168, 215, 0.5);
    box-shadow: 0px 4px 5px 1px rgba(87, 168, 215, 0.5);
}

.inner-banner.payment-banner {
    background: rgb(0, 121, 190);
    background: linear-gradient(0deg, rgba(0, 121, 190, 1) 16%, rgba(0, 94, 149, 1) 72%);
}

.billing-area.hide {
    display: none;
}

.beAHsZ {
    width: 100%;
    margin-bottom: 10px;
}

.iHNqDx {
    padding: 15px !important;
}

.iHNqDx {
    background: transparent !important;
}

.cqQGRX {
    background-color: transparent !important;
    border: 1px solid #e1e2e4 !important;
    border-radius: 4px;
    color: #424542 !important;
}

.beAHsZ {
    width: 100% !important;
    margin-bottom: 20px !important;
}

.kbeEM {
    width: 50% !important;
    padding-left: 0px !important;
}

.igzdhU {
    width: 50% !important;
}

.jPkAPB {
    display: none;
}

.gBytFC {
    width: 60% !important;
    position: absolute;
    bottom: 0px;
    right: 15px;
    font-size: 18px;
}

.hRfUdb {
    background-color: #0069b2 !important;
    border: 1px solid #2e6da4 !important;
    box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08), inset 0 1px 0 #0069b2 !important;
    text-transform: uppercase;
}

.iXDzJJ {
    width: 100%;
}

.payment .iXDzJJ input {
    outline: 0px !important;
}

.payment input:focus, .payment select:focus {
    border: 0 !important;
}

.dYwNxH {
    background: #0069b2 !important;
    box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08), inset 0 1px 0 #0069b2 !important;
}

.payment-form.spinner-loader {
    width: 100%;
}

.payment-form.spinner-loader .css-1ujetdd {
    margin: 0 auto;
    margin-top: 100px;
}

.website-menu .navbar {
    background: #0c73cc !important;
}

.website-menu .dropdown {
    border-radius: 0;
    border: 0;
}

.website-menu .dropdown-menu {
    background: #0c73cc;
    border: 0;
    top: 80%;
    border-radius: 0px 0px 5px 5px;
}

.website-menu .dropdown-item:hover {
    background: #085ca5;
    color: #fff;
}

.website-menu .dropdown-menu {
    color: #fff;
}

.website-menu .navbar .nav-item .nav-link {
    color: #eee !important;

}

.website-menu .navbar .nav-item .nav-link:hover .navbar .nav-item .nav-link {
    color: red !important;

}

.website-menu.main-nav .navbar {
    background: white !important;
}

header .head-nav .main-nav.website-menu .navbar .nav-item .nav-link {
    color: #4e4e4e !important;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
}

header .head-nav .main-nav.website-menu .navbar .nav-item .nav-link.active {
    color: #085ca5 !important;
}

header .head-nav .main-nav.website-menu .navbar .nav-item {
    padding: 20px 0px 20px 40px;
}

header .head-nav .main-nav.website-menu .navbar .nav-item.dropdown {
    top: 0;
}

header .head-nav .main-nav.website-menu .navbar .nav-item .dropdown-menu .dropdown-item {
    padding: 10px 20px !important;
}

header .head-nav .main-nav.website-menu .navbar .nav-item .dropdown-menu .dropdown-item:hover, header .head-nav .main-nav.website-menu ul li a.active {
    color: white;
}

.website-menu .dropdown-item.active, .website-menu .dropdown-item:active {
    background: #085ca5 !important;
    color: white;
}

.website-menu.main-nav .navbar {
    background: white !important;
    padding: 0;
}

.website-menu .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
    padding: 0px;
    background: #fff;
}

.head-top .padright {
    text-align: right;
}

.total_new {
    background: #008cdf;
    padding: 20px 15px;
    color: #ffff;
    border-bottom: 3px solid #f6f6f6;
}

.tit_mem {
    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;
}

.tit_price {
    font-size: 25px;
    font-weight: 600;
    text-transform: uppercase;
    text-align: right;
}

.btn_apply {
    text-align: right;
}

.btn_apply a {
    background: #008cdf;
    display: inline-block;
    margin-top: 15px;
    padding: 10px 30px;
    color: #fff;
    font-weight: 600;
    text-transform: uppercase;
    text-align: right;
    border-radius: 5px;
}


/* payment page end */


.image-boxs {
    width: 100px;
    height: 100px;
    margin: auto;

}

.image-boxs img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}

.btn_news {
    width: 100%;
}

.dentist_details .dentist_name {
    font-size: 25px;
    font-weight: 600;
    margin-bottom: 0px;
}

.bg_search {
    padding-top: 20px;
    padding-bottom: 20px;
    background: #ffff;
    margin: 30px 0px;
    border: 2px solid #f9f9f9;
    border-radius: 10px;

}

.call {
    text-align: center;
}

.call a i {
    margin-right: 10px;
}

.call a {
    background: #007bff;
    color: #fff;
    margin-bottom: 10px;
    display: block;
    padding: 10px;
    border-radius: 5px;
}

.no_dentist {
    padding-top: 50px;
}

.nav-item.dropdown.dmenu.membership-menu:hover .dropdown-menu.sm-menu {
    display: block;
}

.nav-item.dropdown.dmenu.eligibilty-menu:hover .dropdown-menu.sm-menu {
    display: block;
}

.nav-item.dropdown.dmenu.faq-menu:hover .dropdown-menu.sm-menu {
    display: block;
}

.footer-ul li {
    display: block;
}

.modal-150w {
    max-width: 50%;
}

.search-1 .s-area .s-det .set {
    position: relative !important;
}

.search-1 .s-area .s-det .set .call a {
    margin: 0px 0 15px !important
}

.new-danger {
    background: #dd4b39;
    font-size: 14px;
    font-weight: 600;
    vertical-align: middle;
    margin: 0px 0 5px;
    color: white;
    display: inline-block;
    height: 40px;
    line-height: 40px;
    padding: 0px 20px;
    border-radius: 5px;
    bottom: 10px;
    left: 15px;
    position: absolute;
}

.CookieConsent {
    padding: 0;
    align-items: center;
    background: rgb(43, 55, 59);
    color: white;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    left: 0;
    position: fixed;
    width: 100%;
    z-index: 999;
    bottom: 0;
  }

:root{
    --es-width : 1140px;
    --es-blue : #027dc3;
}
.es-container{
    width: 100%;
}

.es-bg-white{
    background: #fff;
}
.es-padding{
    padding: 40px;
}

.es-header{
    background: var(--es-blue);
    width: 100%;
    padding: 20px 0;
}
.es-header h1{
    color: #fff;
    text-align: center;
    margin: 0;
    font-size: 2.3rem;
    line-height: 1.3em;
    font-weight: 700;
}

.main-color{
    color: var(--es-blue);
}
.es-body{
    font-size: 1.4rem;
    padding: 50px 0;
}
.es-body h3{
    font-size: 1.8rem;
    color: var(--es-blue);
    margin-top: 0;
}
.es-separator{
    width: 25%;
    margin: 0 auto;
    border-color: var(--es-blue);
}
.es-text-center{
    text-align: center;
}
.es-list{
    width: 600px;
    margin: 3.5rem auto;
    list-style: none;
    padding: 0;
}
.es-list li:not(:last-child){
    margin-bottom: 10px;
}
.es-note{
    font-style: italic;
    width: 600px;
    margin: 0 auto 50px;
    font-size: 1.4rem;
}
.es-list li {
    display: block;
}
  @media (min-width: 768px) {
    .CookieConsent {
      padding: 0 350px;
    }
  }
  
  @media (max-width: 767px) {
    .CookieConsent {
      padding: 0;
    }
    .gBytFC {
        bottom: -20px;
    }
  }
  