/* dashboard rwd.css */
.prof-det .main-area .area-btm form input, .prof-det .main-area .area-btm form select, .prof-det .main-area .area-btm form textarea {
    background: transparent;
}

@media screen and (min-width: 1150px) and (max-width: 1450px) {
    .prof-det .side .pic {
        margin: 0 auto;
    }

    .prof-det .main-area.add-mem .area-btm table td a {
        font-size: 10px;
    }

    .search-1 .s-area .s-det .set {
        padding: 30px 10px;
    }

    .search-1 .s-area .s-det .set .icon img {
        margin: 5px 0 0;
        width: 60%;
        vertical-align: bottom;
        height: auto;
        border-radius: 50%;
    }

    .search-1 .s-area .s-det .set .icon {
        float: left;
        width: 20%;
    }

    .search-1 .s-area .s-det .set .det {
        float: left;
        width: 43%;
    }

    .search-1 .s-area .s-det .set .rating {
        width: 10%;
        float: left;
    }

    .search-1 .s-area .s-det .set .rating span.orange {
        padding: 2px 5px;
        font-size: 12px;
    }

    .search-1 .s-area .s-det .set .rating span {
        padding: 2px 5px;
        font-size: 12px;
    }

    .search-1 .s-area .s-det .set .call {
        width: 30%;
        float: left;
        text-align: right;
    }

    .search-1 .s-area .s-det .set .call a {
        font-size: 14px;
        margin: 10px 0 15px;
    }

    .search-1 .s-area .s-det .set .call a i {
        padding-right: 3px;
        font-size: 15px;
        top: 1px;
    }

    .search-1 .s-area .s-det .set .call span {
        font-size: 12px;
    }

}

@media screen and (min-width: 900px) and (max-width: 1150px) {
    .prof-det .col-md-3 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 30%;
        flex: 0 0 30%;
        max-width: 30%;
    }

    .prof-det .col-md-9 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 70%;
        flex: 0 0 70%;
        max-width: 70%;
    }

    .prof-det .side .pic {
        margin: 0 auto;
    }

    .prof-det .side-btm ul li {
        padding: 0px 3px;
    }

    .prof-det .side-btm ul li a {
        font-size: 12px;
    }

    section.dash-ban {
        overflow: hidden;
    }

    .prof-det .main-area.add-mem .area-btm table thead th {
        font-size: 13px;
        padding: 12px 10px;
    }

    .prof-det .main-area.add-mem .area-btm table td {
        font-size: 12px;
        padding: 10px;
        line-height: 15px;
    }

    .prof-det .main-area.add-mem .area-btm table td a {
        font-size: 8px;
        padding: 8px;
    }

    .search-1 .col-md-5 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 45%;
        flex: 0 0 45%;
        max-width: 45%;
    }

    .search-1 .col-md-7 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 55%;
        flex: 0 0 55%;
        max-width: 55%;
    }

    .search-1 .s-area .s-det .set {
        padding: 20px 5px;
    }

    .search-1 .s-area .s-det .set .icon {
        float: left;
        width: 25%;
    }

    .search-1 .s-area .s-det .set .det {
        float: left;
        width: 40%;
    }

    .search-1 .s-area .s-det .set .rating {
        width: 10%;
        float: left;
    }

    .search-1 .s-area .s-det .set .rating span.orange {
        padding: 2px 5px;
        font-size: 10px;
    }

    .search-1 .s-area .s-det .set .rating span {
        padding: 2px 5px;
        font-size: 10px;
    }

    .search-1 .s-area .s-det .set .call {
        width: 30%;
        float: left;
        text-align: right;
    }

    .search-1 .s-area .s-det .set .call a {
        font-size: 13px;
        margin: 20px 0 15px;
    }

    .search-1 .s-area .s-det .set .call a i {
        padding-right: 3px;
        font-size: 15px;
        top: 1px;
    }

    .search-1 .s-area .s-det .set .call span {
        font-size: 10px;
    }

    .search-1 .s-area .s-det .set .det h2 span.address {
        display: block;
    }

    .search-1 .s-area .s-det .set .det h2 span.name {
        font-size: 14px;
    }

    .search-1 .s-area .s-det .set .det h2 {
        font-size: 14px;
    }

    header .logo img {
        width: 100%;
    }

    header .dash-menu ul li {
        padding: 0 7px 0 0;
    }

    header .dash-menu ul li a {
        font-size: 12px;
    }

    .prof-det .main-area.add-mem h3 {
        font-size: 15px;
    }

    .prof-det .main-area.add-mem a.add-fam {
        padding: 32px 0px 23px 0px;
        font-size: 12px;
    }

    .prof-det .main-area .area-btm.edit-prof form label {
        font-size: 15px;
    }

    .prof-det .main-area .area-btm.edit-prof form input,
    .prof-det .main-area .area-btm.edit-prof form select,
    .prof-det .main-area .area-btm.edit-prof form textarea {
        font-size: 14px;
        font-weight: 500;
    }

    .prof-det .main-area .area-btm.edit-prof form .subs-exp span.expiry a {
        font-size: 11px;
    }

    .prof-det .main-area .area-btm.edit-prof form .subs-exp span.expiry {
        font-size: 13px;
        left: -80px;
        position: relative;
    }

    .prof-det .side .det h2 {
        font-size: 20px !important;
    }

    .search-1 .s-area .s-det .set .icon img {
        margin: 12px 0 0;
        width: 50%;
        vertical-align: bottom;
        height: auto;
        border-radius: 50%;
    }
}

@media screen and (min-width: 100px) and (max-width: 900px) {
    .search-1 .col-md-5 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .search-1 .col-md-7 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .search-1 .s-area {
        text-align: center;
    }

    .search-1 .s-area .s-det {
        height: 300px;
        padding: 0 20px 0 50px;
    }

    .search-1 .s-area .s-det .set {
        text-align: left;
        padding: 25px;
    }

    .search-1 .s-area .s-det .set .icon {
        width: 25%;
    }

    .search-1 .s-area .s-det .set .icon img {
        width: 43%;
        height: auto;
        margin: 0;
        border-radius: 50%;
    }

    .search-1 .s-area .s-det .set .det {
        width: 75%;
    }

    .search-1 .s-area .s-det .set .det h2 span.address {
        font-size: 14px;
        display: block;
    }

    header .col-md-4 {
        margin: 0;
        -ms-flex: 0 0 65%;
        flex: 0 0 65%;
        max-width: 65%;
    }

    header .col-md-3 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 35%;
        flex: 0 0 35%;
        max-width: 35%;
    }

    header .col-md-5 {
        display: none;
    }

    header .logo img {
        width: 100%;
    }

    .prof-det .col-md-3 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 45%;
        flex: 0 0 45%;
        max-width: 45%;
    }

    .prof-det .col-md-9 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 55%;
        flex: 0 0 55%;
        max-width: 55%;
    }

    .prof-det .main-area .area-top ul.nav li a:hover, .prof-det .main-area .area-top ul.nav li a.active {
        font-size: 14px;
    }

    .prof-det .main-area .area-top ul.nav li a {
        font-size: 14px;
    }

    .prof-det .main-area .area-btm .col-md-6 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .prof-det .main-area .area-btm form label {
        font-size: 15px;
    }

    .prof-det .main-area .area-btm form input, .prof-det .main-area .area-btm form select, .prof-det .main-area .area-btm form textarea {
        padding: 10px 22px;
        margin-bottom: 15px;
        font-size: 14px;
    }

    .prof-det .main-area .area-btm {
        padding: 30px 20px;
    }

    .prof-det .main-area .area-top {
        padding: 0 30px;
    }

    .prof-det .main-area.add-mem .area-btm table thead th {
        font-size: 13px;
        padding: 10px;
    }

    .prof-det .main-area.add-mem .area-btm table td {
        font-size: 13px;
        padding: 20px 5px;
    }

    .prof-det .main-area.add-mem .area-btm table thead tr {
        width: 600px;
        display: block;
    }

    .prof-det .main-area.add-mem .area-btm table tbody {
        height: 300px;
        overflow-x: unset;
        width: auto;
        float: left;
        display: table-cell;
    }

    .prof-det .main-area.add-mem .area-btm table thead {
        background: #f6f7ff;
        border-radius: 8px;
        width: auto;
        display: table-cell;
    }

    .prof-det .main-area.add-mem .area-btm table {
        margin: 50px 0 0;
        width: auto;
        display: block;
        overflow-x: scroll;
    }

    .prof-det .main-area.add-mem .area-btm table td:first-child, .prof-det .main-area.add-mem .area-btm table th:first-child {
        width: 15%;
    }

    .prof-det .main-area.add-mem .area-btm table td:nth-child(2), .prof-det .main-area.add-mem .area-btm table th:nth-child(2) {
        width: 15%;
    }

    .prof-det .main-area.add-mem .area-btm table td:nth-child(3), .prof-det .main-area.add-mem .area-btm table th:nth-child(3) {
        width: 15%;
    }

    .prof-det .main-area.add-mem .area-btm table td:nth-child(4), .prof-det .main-area.add-mem .area-btm table th:nth-child(4) {
        width: 15%;
    }

    .prof-det .main-area.add-mem .area-btm table td:nth-child(5), .prof-det .main-area.add-mem .area-btm table th:nth-child(5) {
        width: 25%;
    }

    .prof-det .main-area.add-mem .area-btm table td:last-child, .prof-det .main-area.add-mem .area-btm table th:last-child {
        width: 15%;
    }

    .prof-det .main-area.add-mem .area-btm table tbody tr {
        width: 600px;
        display: block;
    }

    .prof-det .main-area.add-mem .area-btm table td a {
        font-size: 8px;
    }

    .prof-det .main-area.add-mem .area-btm table td {
        color: #39414e;
        font-size: 13px;
        font-weight: 400;
        padding: 20px 5px;
    }

    a#hamburg {
        z-index: 99999;
    }

    .prof-det .main-area.add-mem h3 {
        font-size: 13px;
        padding: 21px 0px;
    }

    .prof-det .main-area.add-mem a.add-fam {
        padding: 20px 0px;
        font-size: 11px;
    }

    .prof-det .main-area .area-btm.edit-prof .col-md-7 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .prof-det .main-area .area-btm.edit-prof form .subs-exp span.expiry {
        display: inline-block;
        font-size: 12px;
        position: relative;
        left: -80px;
    }

    .prof-det .main-area .area-btm.edit-prof form .subs-exp span.expiry a {
        font-size: 12px;
    }
}


@media screen and (min-width: 100px) and (max-width: 900px) {
    .prof-det .side .pic {
        text-align: center;
        position: relative;
        margin: 0 auto;
    }

    .prof-det .side .det h2 {
        font-size: 20px !important;
    }

    .prof-det .col-md-3 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 45%;
        flex: 0 0 40%;
        max-width: 40%;
    }

    .prof-det .col-md-9 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 55%;
        flex: 0 0 60%;
        max-width: 60%;
    }

    .prof-det .side-btm ul li {
        display: block;
    }

    .prof-det .main-area .area-btm.edit-prof form .subs-exp input {
        margin: 0;
    }

    .prof-det .main-area .area-btm.edit-prof form .subs-exp span.expiry {
        display: inline-block;
        font-size: 14px;
        position: relative;
        left: 0;
        margin-bottom: 20px;
    }

    .search-1 .s-area .s-det .set .det {
        width: 37%;
    }

    .search-1 .s-area .s-det .set .call {
        width: 30%;
    }

    .prof-det .main-area.add-mem .col-md-4.offset-md-3.text-right {
        width: 50%;
    }

    .prof-det .main-area.add-mem .col-md-5 {
        width: 50%;
    }
}


@media screen and (min-width: 100px) and (max-width: 700px) {
    .prof-det .side .pic {
        text-align: center;
        position: relative;
        margin: 0 auto;
    }

    .prof-det .col-md-3 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .prof-det .col-md-9 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    header .col-md-4 {
        margin: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .prof-det .main-area.add-mem .area-top .text-right {
        text-align: left !important;
    }

    .search-1 .s-area .s-det .set .rating {
        width: 100%;
        float: left;
        margin: 10px 0;
    }

    .search-1 .s-area .s-det .set .call {
        width: 100%;
        margin: 0 auto;
        text-align: center;
    }

    header .col-md-3 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .prof-det .side-btm ul li {
        display: block;
    }

    header .logo {
        text-align: center;
    }

    header .logo img {
        width: 80%;
        margin: 0 auto;
    }

    header .head-info {
        padding: 15px 0 0;
        text-align: center !important;
    }

    .prof-det .side-btm {
        margin-bottom: 30px;
    }

    .prof-det .main-area .area-top ul.nav li:first-child {
        margin-right: 10px;
    }

    .prof-det .main-area .area-top ul.nav li a {
        padding: 30px 0px 25px 0px;
    }

    .prof-det .main-area.add-mem h3 {
        padding: 30px 0px 25px 0px;
    }

    .prof-det .main-area.add-mem .area-btm form a.addmore {
        margin-left: 10px;
    }

    .prof-det .main-area.add-mem .area-btm table thead tr {
        width: 600px;
        display: block;
    }

    .prof-det .main-area.add-mem .area-btm table tbody {
        height: 300px;
        overflow-x: unset;
        width: auto;
        float: left;
        display: table-cell;
    }

    .prof-det .main-area.add-mem .area-btm table thead {
        background: #f6f7ff;
        border-radius: 8px;
        width: auto;
        display: table-cell;
    }

    .prof-det .main-area.add-mem .area-btm table {
        margin: 50px 0 0;
        width: auto;
        display: block;
        overflow-x: scroll;
    }

    .prof-det .main-area.add-mem .area-btm table td:first-child, .prof-det .main-area.add-mem .area-btm table th:first-child {
        width: 15%;
    }

    .prof-det .main-area.add-mem .area-btm table td:nth-child(2), .prof-det .main-area.add-mem .area-btm table th:nth-child(2) {
        width: 15%;
    }

    .prof-det .main-area.add-mem .area-btm table td:nth-child(3), .prof-det .main-area.add-mem .area-btm table th:nth-child(3) {
        width: 15%;
    }

    .prof-det .main-area.add-mem .area-btm table td:nth-child(4), .prof-det .main-area.add-mem .area-btm table th:nth-child(4) {
        width: 15%;
    }

    .prof-det .main-area.add-mem .area-btm table td:nth-child(5), .prof-det .main-area.add-mem .area-btm table th:nth-child(5) {
        width: 25%;
    }

    .prof-det .main-area.add-mem .area-btm table td:last-child, .prof-det .main-area.add-mem .area-btm table th:last-child {
        width: 15%;
    }

    .prof-det .main-area.add-mem .area-btm table tbody tr {
        width: 600px;
        display: block;
    }

    .prof-det .main-area.add-mem .area-btm table td a {
        font-size: 11px;
        padding: 7px;
        display: inline-block;
        text-align: center;
    }

    .search-1 .s-area .s-det .set {
        text-align: center;
        padding: 10px 15px;
    }

    .search-1 .s-area .s-det .set .icon {
        width: auto;
        float: none;
    }

    .search-1 .s-area .s-det .set .det {
        width: auto;
        float: none;
    }

    .search-1 .s-area .s-det .set .det p {
        font-size: 13px;
        margin: 10px 0 5px;
    }

    .search-1 .s-area .s-det .set .det h2 {
        font-size: 14px;
    }

    .search-1 .s-area .s-det .set .det h2 span.name {
        font-size: 14px;
        padding: 5px 0;
        display: block;
    }

    .search-1 .s-area .s-det .set .det h2 span.address {
        font-size: 14px;
        padding: 5px 0;
    }

    .search-1 .s-area .meta h4 {
        font-size: 20px;
    }

    .prof-det .main-area.add-mem .col-md-5 {
        width: 60%;
    }

    .prof-det .main-area.add-mem .col-md-4.offset-md-3.text-right {
        width: 40%;
    }

    .prof-det .main-area.add-mem h3 {
        font-size: 11px;
        padding: 21px 0px;
    }

    .prof-det .main-area.add-mem a.add-fam {
        padding: 20px 0px 0;
        font-size: 7px;
        text-align: right;
        display: block;
    }

    .search-1 .s-area .s-det .set .icon img {
        width: 30%;
        height: auto;
        margin: 0 0 20px;
        border-radius: 50%;
    }

    .image-box {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        float: none;
        margin: 0 auto;
    }
}

@media screen and (min-width: 550px) and (max-width: 700px) {
    header .logo img {
        width: 40%;
        margin: 0 auto;
    }

    .prof-det .side .pic a.edit {
        position: absolute;
        right: 32%;
        bottom: 8%;
        width: auto;
    }

    .prof-det .side-btm ul li {
        display: inline-block;
    }

    .prof-det .main-area .area-top ul.nav li a:hover, .prof-det .main-area .area-top ul.nav li a.active {
        font-size: 18px;
    }

    .prof-det .main-area .area-top ul.nav li a {
        font-size: 18px;
    }

    .prof-det .main-area .area-top ul.nav li:first-child {
        margin-right: 30px;
    }

    .prof-det .main-area.add-mem .area-btm table thead {
        width: 100%;
        display: block;
    }

    .search-1 .s-area .s-det {
        height: 300px;
        padding: 0 20px 0 50px;
    }

    .prof-det .main-area.add-mem h3 {
        font-size: 15px;
    }

    .prof-det .main-area.add-mem a.add-fam {
        font-size: 12px;
    }

    .prof-det .main-area .area-btm.edit-prof form .subs-exp span.expiry {
        left: -70px;
    }

    .search-1 .s-area .s-det .set .icon img {
        width: 12%;
        height: auto;
        margin: 0 0 20px;
        border-radius: 50%;
    }
}

@media screen and (min-width: 360px) and (max-width: 500px) {
    .prof-det .side .pic {
        margin: 0 auto;
    }

    header .logo img {
        width: 65%;
        margin: 0 auto;
    }
}

.nav1 ul li a.active {
    color: #3f7ec6;
}

.search-1 .s-area .s-det .login-user.set .call {
    width: auto;
    float: none;
    text-align: right;
}

.search-1 .s-area .s-det .login-user.set .call a {
    color: white;
}