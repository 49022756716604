/* dashboard custom.css */
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i|Poppins:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&display=swap');

body, html {
    font-family: 'Poppins', sans-serif !important;
}

body {
    background: #fff;
}

.nopad {
    padding: 0;
}

.padleft {
    padding-left: 0;
}

.padright {
    padding-right: 0;
}

a:hover, a:active {
    text-decoration: none !important;
}

* :focus {
    outline: 0px auto -webkit-focus-ring-color !important;
    outline-offset: 0 !important;
    text-decoration: none !important;
}

* a:hover {
    text-decoration: none !important;
}

ul {
    padding: 0;
    margin: 0;
}

ul li {
    list-style: none;
    display: inline-block;
}

#return-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background: #ffbf3d;
    width: 50px;
    height: 50px;
    display: block;
    text-decoration: none;
    -webkit-border-radius: 35px;
    -moz-border-radius: 35px;
    border-radius: 35px;
    display: none;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    z-index: 9999;
    line-height: 50px;
    text-align: center;
    color: #fff;
    font-size: 25px;
}

#return-to-top:hover {
    background: rgba(0, 0, 0, 0.9);
}

#return-to-top:hover i {
    color: #fff;
    top: 5px;
}


nav.nav1 {
    display: none;
}

nav.nav1 .logo {
    text-align: center;
    margin: 20px 0 30px;
}

nav.nav1 .logo img {
    max-width: 80%;
    margin: 0 auto;
}

/*----------------------------------------Push Navigation---------------------------------*/
div#menuoverlay {
    display: none;
}

a#hamburg {
    position: fixed;
    top: 0px;
    left: 10px;
    width: 40px;
    height: 40px;
    z-index: 9;
    text-align: center;
    color: white;
    display: none;
    transition: all ease-out 0.3s;
}

a#hamburg i {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
}

.nav1 {
    transition: all ease-out 0.3s;
    display: none;
}

.nav1 a {
    color: #000;
}

.nav1 ul {
    margin: 0;
    padding: 0;
    background-color: #303030;
}

.nav1 ul li {
    display: inline-block;
    position: relative;
    padding: 10px 20px !important;
}

.nav1 ul li > a {
    display: inline-block;
    padding: 10px;
}

.nav1 ul li > a > i {
    margin-left: 15px;
    transition: all ease-out 0.3s;
    -webkit-transition: all ease-out 0.1s;
}

.nav1 ul li ul {
    display: none;
    position: absolute;
    top: 38px;
    background: #222;
    width: 200px;
    text-align: left;
}

.nav1 .search-nav, .nav1 .title {
    display: none;
}

.nav1 ul li ul li {
    display: block;
}

.nav1 ul li ul li a {
    display: block;
}

.nav1 ul li:hover > a {
    color: #000;
    text-decoration: none;
}

.nav1 ul li:hover > a > i {
    transform: rotateZ(90deg);
    -webkit-transform: rotateZ(90deg);
}

.nav1 ul li:hover ul {
    display: block;
}

.nav1 span.marrow {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #fff;
    float: right;
    height: 0;
    margin: 8px 0 0 8px;
    width: 0;
}

.nav1 ul li.open span.marrow {
    border-left: 5px solid transparent;
    border-top: 0;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #fff;
}

@media screen and (max-width: 1024px) {

    .slider .item .carousel-caption {
        top: 80px;
    }

    .slider-text h3 {
        font-size: 40px;
    }

    .slider-text p {
        font-size: 16px;
        margin-bottom: 25px;
    }

    .slider-text .form-group label {
        font-size: 12px;
    }

}

.open-menu .nav-cross:before {
    content: "x";
    position: absolute;
    z-index: -1;
    font-size: 22px;
    background: #3f7ec6;
    font-weight: 500;
    width: 33px;
    height: 33px;
    top: 10px;
    left: 0;
}


@media screen and (max-width: 900px) {
    nav.nav1 {
        display: block;
    }

    .logo-bar nav {
        display: none !important;
    }

    a#hamburg {
        display: block;
    }

    main {
        transition: all ease-out 0.3s;
    }

    .nav1 .row {
        margin: 0px;
    }

    .nav1 {
        background-color: #fff;
        box-shadow: -2px 0 10px #999 inset;
        display: block;
        height: 100%;
        left: 0;
        overflow-x: hidden;
        overflow-y: auto;
        position: fixed;
        top: 0;
        transform: translateX(-250px);
        -webkit-transform: translateX(-250px);
        width: 250px;
    }

    .nav1 .search-nav, .nav1 .title {
        display: block;
    }

    .nav1 .search-nav input {
        border: 1px solid #2c313f;
        background-color: rgba(255, 255, 255, 0.2);
        padding: 5px;
        width: 100%;
        color: #000;
        margin: 10px 0;
    }

    .nav1 .search-nav input[type="submit"] {
        background: url("../../images/search-icon.png") no-repeat scroll center center;
        border: 0;
        width: auto;
        font-size: 0;
        height: 16px;
        position: absolute;
        right: 7px;
        top: 8px;
        width: 16px;
        filter: invert(100%);
    }

    .nav1 .title {
        background: #000;
        color: #fff;
        font-size: 14px;
        margin: 0 -15px;
        padding: 6px 15px 6px;
    }

    .search-nav > form {
        position: relative;
    }

    .nav1 ul {
        text-align: left;
        background-color: transparent;
    }

    .nav1 ul li {
        display: block;
        border-bottom: 1px solid #999;
    }

    .nav1 ul li a {
        display: block;
        padding: 0;
    }

    .nav1 ul li:hover > a {
        color: #000;
    }

    .nav1 ul li a > i {
        float: right;
    }

    .nav1 ul li ul {
        display: none;
        position: static;
        width: 100%;
        background-color: transparent;
        padding: 0 10px;
    }

    .nav1 ul li:hover > ul {
        display: none;
    }

    .nav1 ul li:hover > a > i {
        transform: rotateZ(0);
        -webkit-transform: rotateZ(0);
    }

    .nav1 ul li.open > a {
        background-color: transparent;
    }

    .nav1 ul li.open > a > i {
        transform: rotateZ(90deg);
        -webkit-transform: rotateZ(90deg);
    }

    .nav1 ul li.open > ul {
        display: block;
    }

    div#menuoverlay {
        display: block;
        visibility: hidden;
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        transition: all ease-out 0.3s;
        z-index: 1;
        opacity: 0;
    }

    html.open-menu {
        overflow: hidden;
    }

    html.open-menu div#menuoverlay {
        visibility: visible;
        background: rgba(0, 0, 0, 0.5);
        min-height: 100vh;
        width: 100% !important;
        opacity: 1;
        width: calc(-150%);
        left: 250px;
    }

    html.open-menu a#hamburg {
        transform: translateX(250px);
        -webkit-transform: translateX(250px);
        z-index: 999;
    }

    html.open-menu main {
        transform: translateX(250px);
        -webkit-transform: translateX(250px);
    }

    html.open-menu nav.nav1 {
        z-index: 99999;
        transform: translateX(0);
        -webkit-transform: translateX(0);
    }

    .menu-main-menu-container ul ul ul li a {
        padding: 5px;
    }

    ul li.has-subnav .accordion-btn {
        color: #000;
        font-size: 24px;
        width: 20px !important;
        float: right;
        line-height: inherit !important;
    }

    .nav1 ul li:last-child {
        border: 0;
    }

    .nav-cross.open > span {
        background: #fff;
    }

    /*---------------------Hamberg Icon --------------------------------------------- */
    .nav-cross {
        width: 24px;
        height: 40px;
        position: relative;
        margin: 0px auto;
        transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        transition: .5s ease-in-out;
        cursor: pointer;
    }

    .nav-cross span {
        display: block;
        position: absolute;
        height: 2px;
        width: 100%;
        background: #000;
        opacity: 1;
        left: 0;
        transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        transition: .25s ease-in-out;
    }

    .open-menu .nav-cross span {
        display: none;
    }

    #nav-icon1 span:nth-child(1) {
        top: 0px;
    }

    #nav-icon1 span:nth-child(2) {
        top: 18px;
    }

    #nav-icon1 span:nth-child(3) {
        top: 36px;
    }

    #nav-icon1.open span:nth-child(1) {
        top: 18px;
        transform: rotate(135deg);
        -webkit-transform: rotate(135deg);
    }

    #nav-icon1.open span:nth-child(2) {
        opacity: 0;
        left: -60px;
    }

    #nav-icon1.open span:nth-child(3) {
        top: 18px;
        transform: rotate(-135deg);
        -webkit-transform: rotate(-135deg);
    }

    .nav-cross span:nth-child(1) {
        top: 12px;
        transform-origin: left center;
        -webkit-transform-origin: left center;
    }

    .nav-cross span:nth-child(2) {
        top: 20px;
        transform-origin: left center;
        -webkit-transform-origin: left center;
    }

    .nav-cross span:nth-child(3) {
        top: 28px;
        transform-origin: left center;
        -webkit-transform-origin: left center;
    }

    .nav-cross.open span:nth-child(1) {
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        top: 11px;
        left: 4px;
    }

    .nav-cross.open span:nth-child(2) {
        width: 0%;
        opacity: 0;
    }

    .nav-cross.open span:nth-child(3) {
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        top: 28px;
        left: 4px;
    }
}


/*Scroll css Start here*/


body::-webkit-scrollbar {
    width: 10px;
}

body::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 30px #000;
}

body::-webkit-scrollbar-thumb {
    background-color: #0576c2;
    outline: 1px solid #0576c2;
}

/*Scroll css End here*/

/*btn*/

a.btn-wt {
    color: #0069b2;
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    padding: 14px 40px;
    background: #fff;
    -webkit-box-shadow: 0px 4px 5px 1px rgba(87, 168, 215, 0.5);
    -moz-box-shadow: 0px 4px 5px 1px rgba(87, 168, 215, 0.5);
    box-shadow: 0px 4px 5px 1px rgba(87, 168, 215, 0.5);
    display: inline-block;
    transition: all ease-out .5s;
}

a.btn-wt:hover {
    background: #0069b2;
    color: #fff;
    transition: all ease-out .5s;
}

a.btn-bl {
    color: #fff;
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    padding: 14px 40px;
    background: #0069b2;
    -webkit-box-shadow: 0px 4px 5px 1px rgba(87, 168, 215, 0.5);
    -moz-box-shadow: 0px 4px 5px 1px rgba(87, 168, 215, 0.5);
    box-shadow: 0px 4px 5px 1px rgba(87, 168, 215, 0.5);
    display: inline-block;
    transition: all ease-out .5s;
}

a.btn-bl:hover {
    background: #fff;
    color: #0069b2;
    transition: all ease-out .5s;
}


.wd-95 {
    width: 95%;
    margin: 0 auto;
}

.pd-top-60 {
    padding-top: 60px;
}


/*header*/

header {
    padding: 18px 0;
}

header .logo {

}

header .head-info {
    padding: 5px 0;
}

header .head-info ul {

}

header .head-info ul li {
    position: relative;
}

header .head-info ul li.notif {
    vertical-align: middle;
    margin-right: 30px;
}

header .head-info ul li.notif a {
    vertical-align: middle;
}

header .head-info ul li.notif a i {
    color: #77889a;
    font-size: 25px;
    vertical-align: middle;
}

header .head-info ul li.notif a span {
    position: absolute;
    background: #fa2c2c;
    width: 22px;
    height: 22px;
    border-radius: 100%;
    border: 2px solid #fff;
    color: #fff;
    text-align: center;
    right: -10px;
    font-size: 12px;
    top: -9px;
    font-weight: 500;
}

header .head-info ul li.prof-opt {

}

header .head-info ul li.prof-opt img {
    /*padding-right: 18px;*/
    vertical-align: middle;
}

header .head-info ul li.prof-opt a {
    color: #4e4e4e;
    font-weight: 400;
    font-size: 16px;
    vertical-align: middle;
}

header .head-info ul li.prof-opt a i {
    padding-left: 10px;
}

.dash-ban {
    background-size: 100%;
}


/*prof-det*/

.prof-det {
    padding-bottom: 80px;
}

.prof-det .side {
    border: 2px solid #e1e2e4;
    border-top-left-radius: 5px;
    border-bottom: none;
    border-top-right-radius: 5px;
    background: #fff;
    padding: 25px 12px;
}

.prof-det .side .pic {
    text-align: center;
    position: relative;
    margin: 0 60px;
}

.prof-det .side .pic img {
    border-radius: 100%;
}

.prof-det .side .pic a.edit {
    position: absolute;
    right: 0;
    bottom: 8%;
    width: auto;
}

.prof-det .side .pic a.edit i {
    color: #fff;
    font-size: 20px;
    border-radius: 100%;
    border: 4px solid #fff;
    background: #0069b2;
    padding: 8px;
}

.prof-det .side .det {
    margin: 30px 0;
}

.prof-det .side .det h2 {
    margin: 0;
    color: #4e4e4e;
    font-weight: 500;
    font-size: 23px;
    text-transform: capitalize;
}

.prof-det .side .det h5 {
    margin: 10px 0;
    color: #606060;
    font-weight: 300;
    font-size: 16px;
    text-transform: capitalize;
}

.prof-det .side .det p {
    margin: 0;
    color: #2c4059;
    font-size: 15px;
    font-weight: 300;
    vertical-align: middle;
}

.prof-det .side .det p i {
    padding-right: 10px;
    color: #2c4059;
    font-size: 18px;
    vertical-align: middle;
}

.prof-det .side .add-btn {
    margin: 25px 0 10px;
}

.prof-det .side .add-btn a {
    border: 1px solid #e1e1e1;
    padding: 17px 0;
    text-align: center;
    width: 100%;
    font-weight: 500;
    font-size: 16px;
    color: #5477f2;
    display: inline-block;
    border-radius: 5px;
    vertical-align: middle;
}

.prof-det .side .add-btn a:hover {
    background: #5477f2;
    color: #fff;
    transition: all ease .5s;
}

.prof-det .side .add-btn a i {
    padding-right: 10px;
    vertical-align: middle;
    font-size: 18px;
}

.prof-det .side-btm {
    border: 2px solid #e1e2e4;
    border-top: 1px solid #e1e2e4;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background: #fff;
    padding: 25px 0;
}

.prof-det .side-btm ul {
    text-align: center;
}

.prof-det .side-btm ul li {
    padding: 0px 10px;
}

.prof-det .side-btm ul li a {
    color: #4e4e4e;
    font-size: 13px;
    font-weight: 400;
}

.prof-det .main-area {
    border: 2px solid #e1e2e4;
    background: #fff;
    border-radius: 5px;
    background: #fff;
}

.prof-det .main-area .area-top {
    border-bottom: 1px solid #e5e8ec;
    padding: 0 40px;
}

.prof-det .main-area .area-top ul.nav {

}

.prof-det .main-area .area-top ul.nav li {

}

.prof-det .main-area .area-top ul.nav li:first-child {
    margin-right: 15px;
}

.prof-det .main-area .area-top ul.nav li a {
    color: #7b8b9d;
    font-size: 20px;
    font-weight: 400;
    padding: 30px 40px 25px 0px;
    display: block;
}

.prof-det .main-area .area-top ul.nav li a:hover, .prof-det .main-area .area-top ul.nav li a.active {
    color: #4e4e4e;
    font-size: 20px;
    border-bottom: 2px solid #5477f2;
}

.prof-det .main-area .area-btm {
    padding: 50px 40px;
    min-height: 775px;
}

.prof-det .main-area .area-btm .tab-content {

}

.prof-det .main-area .area-btm form label {
    color: #666666;
    font-weight: 400;
    font-size: 18px;
}

.prof-det .main-area .area-btm form input, .prof-det .main-area .area-btm form select, .prof-det .main-area .area-btm form textarea {
    width: 100%;
    border: 1px solid #e1e1e1;
    padding: 15px 22px;
    border-radius: 5px;
    margin-bottom: 22px;
    font-size: 17px;
    font-weight: 300;
    resize: none;
    color: #666666;
}


.contact-1 .box .lf input:focus, .contact-1 .box .lf textarea:focus, .contact-1 .box .lf select:focus {
    border: 1px solid #73beea;
}

.prof-det .main-area .area-btm form input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #666666;
}

.prof-det .main-area .area-btm form input::-moz-placeholder { /* Firefox 19+ */
    color: #666666;
}

.prof-det .main-area .area-btm form input:-ms-input-placeholder { /* IE 10+ */
    color: #666666;
}

.prof-det .main-area .area-btm form input:-moz-placeholder { /* Firefox 18- */
    color: #666666;
}

.prof-det .main-area .area-btm form select::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #666666;
}

.prof-det .main-area .area-btm form select::-moz-placeholder { /* Firefox 19+ */
    color: #666666;
}

.prof-det .main-area .area-btm form select:-ms-input-placeholder { /* IE 10+ */
    color: #666666;
}

.contact-1 .box .lf select:-moz-placeholder { /* Firefox 18- */
    color: #2c4059;
}

.prof-det .main-area .area-btm form textarea::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #666666;
}

.prof-det .main-area .area-btm form textarea::-moz-placeholder { /* Firefox 19+ */
    color: #666666;
}

.prof-det .main-area .area-btm form textarea:-ms-input-placeholder { /* IE 10+ */
    color: #666666;
}

.prof-det .main-area .area-btm form textarea:-moz-placeholder { /* Firefox 18- */
    color: #666666;
}


.prof-det .main-area .area-btm form input:focus, .prof-det .main-area .area-btm form select:focus, .prof-det .main-area .area-btm form textarea:focus {
    border: 1px solid #73beea;
}


.prof-det .main-area .area-btm form input[type="submit"] {
    color: #fff;
    text-transform: capitalize;
    font-size: 18px;
    font-weight: 500;
    width: auto;
    padding: 12px 50px;
    background: #5477f2;
    -webkit-box-shadow: 0px 4px 5px 1px rgba(87, 168, 215, 0.5);
    -moz-box-shadow: 0px 4px 5px 1px rgba(87, 168, 215, 0.5);
    box-shadow: 0px 4px 5px 1px rgba(87, 168, 215, 0.5);
    transition: all ease-out .5s;
    cursor: pointer;
}


.prof-det .main-area.add-mem {

}

.prof-det .main-area.add-mem h3 {
    margin: 0;
    color: #4e4e4e;
    font-weight: 500;
    font-size: 20px;
    position: relative;
    padding: 30px 30px 25px 0px;
    display: inline-block;
}

.prof-det .main-area.add-mem h3:before {
    content: '';
    position: absolute;
    background: #5477f2;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
}

.prof-det .main-area.add-mem .area-top {

}

.prof-det .main-area.add-mem .area-btm form a.addmore {
    color: #476be9;
    font-weight: 500;
    font-size: 14px;
    margin-left: 30px;
}

.prof-det .main-area.add-mem .area-btm table {
    margin: 50px 0 0;
    width: 100%;
    display: block;
}

.prof-det .main-area.add-mem .area-btm table thead {
    background: #f6f7ff;
    border-radius: 8px;
    width: 100%;
    display: block;
}

.prof-det .main-area.add-mem .area-btm table tbody {
    height: 300px;
    overflow-x: hidden;
    width: 100%;
    float: left;
}

.prof-det .main-area.add-mem .area-btm table tbody::-webkit-scrollbar {
    width: 5px;

}

.prof-det .main-area.add-mem .area-btm table tbody::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 30px #5477f2;
    border-radius: 10px;
}

.prof-det .main-area.add-mem .area-btm table tbody::-webkit-scrollbar-thumb {
    background-color: #f6f7ff;
    outline: 1px solid #f6f7ff;
    border-radius: 10px;
}

/*Scroll css End here*/
.prof-det .main-area.add-mem .area-btm table tbody tr {
    border-bottom: 1px solid #eff0f5;
}

.prof-det .main-area.add-mem .area-btm table tbody tr:last-child {
    border: none;
}

.prof-det .main-area.add-mem .area-btm table thead th {
    color: #333333;
    font-size: 17px;
    font-weight: 400;
    padding: 12px 20px;
}

.prof-det .main-area.add-mem .area-btm table td {
    color: #39414e;
    font-size: 17px;
    font-weight: 400;
    padding: 20px 20px 20px 20px;
}

.prof-det .main-area.add-mem .area-btm table td:first-child, .prof-det .main-area.add-mem .area-btm table th:first-child {
    width: 127px;
}

.prof-det .main-area.add-mem .area-btm table td:nth-child(2), .prof-det .main-area.add-mem .area-btm table th:nth-child(2) {
    width: 127px;
}

.prof-det .main-area.add-mem .area-btm table td:nth-child(3), .prof-det .main-area.add-mem .area-btm table th:nth-child(3) {
    width: 150px;
}

.prof-det .main-area.add-mem .area-btm table td:nth-child(4), .prof-det .main-area.add-mem .area-btm table th:nth-child(4) {
    width: 127px;
}

.prof-det .main-area.add-mem .area-btm table td:nth-child(5), .prof-det .main-area.add-mem .area-btm table th:nth-child(5) {
    width: 220px;
}

.prof-det .main-area.add-mem .area-btm table td:last-child, .prof-det .main-area.add-mem .area-btm table th:last-child {
    width: 127px;
}

.prof-det .main-area.add-mem .area-btm table td a {
    background: #f0f1fd;
    border-radius: 5px;
    color: #3751ff;
    font-size: 14px;
    font-weight: 400;
    padding: 8px;
}


/*search-1*/
body.search-dentist header {
    -webkit-box-shadow: 0px 0px 27px 8px rgba(0, 0, 0, 0.08);
    -moz-box-shadow: 0px 0px 27px 8px rgba(0, 0, 0, 0.08);
    box-shadow: 0px 0px 27px 8px rgba(0, 0, 0, 0.08);
    position: relative;
    z-index: 9;
}

.search-1 {
    overflow: hidden;
}

.search-1 .s-area {
    padding: 35px 0;
}

.search-1 .s-area .field {
    position: relative;
    margin: 0 40px 0 50px;
}

.search-1 .s-area .field:before {
    content: '';
    position: absolute;
    width: 23px;
    height: 23px;
    top: 15px;
    left: 25px;
    background: url(../../images/search-icon.png) no-repeat;
}

.search-1 .s-area .field input[type="search"] {
    width: 100%;
    border: 1px solid #ebeef2;
    border-radius: 5px;
    background: #f5f7f9;
    color: #9ba9b5;
    font-size: 16px;
    font-weight: 300;
    padding: 14px 10px 12px 60px;
}

.search-1 .s-area .meta {
    margin: 25px 40px 10px 50px;
}

.search-1 .s-area .meta h4 {
    margin: 0;
    color: #4e4e4e;
    font-size: 23px;
    font-weight: 500;
    vertical-align: middle;
}

.search-1 .s-area .meta h4 span {
    color: #c7ceda;
    font-weight: 400;
    font-size: 20px;
    padding-left: 20px;
    vertical-align: middle;
}

.search-1 .s-area .s-det {
    height: calc(100vh - 280px);
    overflow-x: hidden;
    scroll-behavior: smooth;
    padding: 0 20px 0 50px;
    margin-right: 20px;
}

.search-1 .s-area .s-det::-webkit-scrollbar {
    width: 5px;

}

.search-1 .s-area .s-det::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 30px #5477f2;
    border-radius: 10px;
}

.search-1 .s-area .s-det::-webkit-scrollbar-thumb {
    background-color: #f6f7ff;
    outline: 1px solid #f6f7ff;
    border-radius: 10px;
    border-radius: 10px;
}

.search-1 .s-area .s-det .set {
    background: #fff;
    overflow: hidden;
    padding: 30px 20px;
    margin: 20px 0;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.08);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.08);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.08);
    border: 1px solid #eceff3;
    border-radius: 10px;
    transition: all ease .5s;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
}

.search-1 .s-area .s-det .set:hover {
    box-shadow: 0px 0px 18px 4px rgba(0, 0, 0, 0.08);
    transition: all ease .5s;
    border-left: 5px solid #5577f2;
    border-right: 5px solid #5577f2;
}

.search-1 .s-area .s-det .set .icon {
    float: left;
    width: 15%
}

.search-1 .s-area .s-det .set .icon img {
    margin: 12px 0 0;
    width: 70px;
    vertical-align: bottom;
    height: 22px;
    border-radius: 50%;
}

.search-1 .s-area .s-det .set .det {
    float: left;
    width: 40%
}

.search-1 .s-area .s-det .set .det p {
    display: block;
    color: #9a9a9a;
    font-size: 13px;
    font-weight: 300;
    margin: 0;
}

.search-1 .s-area .s-det .set .det h2 {
    margin: 0;
    color: #4e4e4e;
    font-weight: 500;
    font-size: 17px;
}

.search-1 .s-area .s-det .set .det h2 span.name {
    margin: 0;
    color: #4e4e4e;
    font-weight: 500;
    font-size: 17px;
    padding: 0;
    display: block;
}

.search-1 .s-area .s-det .set .det h2 span.address {
    color: #c3c3c3;
    margin: 0;
    font-size: 13px;
    font-weight: 300;
}

.search-1 .s-area .s-det .set .rating {
    width: 15%;
    float: left;
}

.search-1 .s-area .s-det .set .rating span {
    background: #d7e0ff;
    color: #5276f1;
    padding: 3px 15px;
}

.search-1 .s-area .s-det .set .rating span.orange {
    background: #fff1e4;
    padding: 3px 15px;
    color: #ff7e04;
}

.search-1 .s-area .s-det .set .call {
    width: 30%;
    float: left;
    text-align: right;
}

.search-1 .s-area .s-det .set .call a {
    color: #0068af;
    font-size: 18px;
    font-weight: 600;
    vertical-align: middle;
    margin: 10px 0 15px;
    display: inline-block;
}

.search-1 .s-area .s-det .set .call a i {
    padding-right: 7px;
    transform: rotate(45deg);
    vertical-align: middle;
    font-size: 20px;
    top: 2px;
    position: relative;
}

.search-1 .s-area .s-det .set .call span {
    color: #b4bec8;
    font-size: 14px;
    font-weight: 400;
    display: block;
}

.search-1 .m-area {
}

.search-1 .m-area iframe {
    height: calc(100vh - 100px);
}


/*new pages 14-4-20*/

.prof-det .main-area.add-mem a.add-fam {
    display: inline-block;
    padding: 32px 30px 23px 0px;
    font-weight: 500;
    font-size: 16px;
    color: #5477f2;
    border-radius: 5px;
    vertical-align: middle;
}

.prof-det .main-area.add-mem a.add-fam i {
    padding-right: 5px;
    vertical-align: middle;
    font-size: 18px;
}

.prof-det .main-area .area-btm.new-add table.add-member {
    margin: 0;
}

.prof-det .main-area .area-btm.new-add table tbody {
    height: 500px;
}

.prof-det .main-area .area-btm.edit-prof form label {
    color: #7b8b9d;
    font-weight: 400;
    font-size: 18px;
}

.prof-det .main-area .area-btm.edit-prof form input,
.prof-det .main-area .area-btm.edit-prof form select,
.prof-det .main-area .area-btm.edit-prof form textarea {
    width: 100%;
    border: none;
    padding: 0;
    border-radius: 5px;
    margin-bottom: 22px;
    font-size: 17px;
    font-weight: 500;
    resize: none;
    color: #666666;
}

.prof-det .main-area .area-btm.edit-prof form textarea {
    height: 180px;
}

.prof-det .main-area .area-btm.edit-prof form input[type="submit"] {
    color: #fff;
    text-transform: capitalize;
    font-size: 18px;
    font-weight: 500;
    width: auto;
    padding: 12px 50px;
    background: #5477f2;
    -webkit-box-shadow: 0px 4px 5px 1px rgba(87, 168, 215, 0.5);
    -moz-box-shadow: 0px 4px 5px 1px rgba(87, 168, 215, 0.5);
    box-shadow: 0px 4px 5px 1px rgba(87, 168, 215, 0.5);
    transition: all ease-out .5s;
    cursor: pointer;
}

.prof-det .main-area .area-btm.edit-prof form .subs-exp label {
    color: #7b8b9d;
    font-weight: 400;
    display: block;
}

.prof-det .main-area .area-btm.edit-prof form .subs-exp input {
    width: auto;
    display: inline-block;
    border: none;
}

.prof-det .main-area .area-btm.edit-prof form .subs-exp span.expiry {
    display: inline-block;
}

.prof-det .main-area .area-btm.edit-prof form .subs-exp span.expiry a {
    color: #5477f2;
    font-size: 18px;
    font-weight: 500;
}

header .dash-menu {
    text-align: right;
    padding: 15px 0;
}

header .dash-menu ul {

}

header .dash-menu ul li {
    padding: 0 20px 0 0;
}

header .dash-menu ul li a {
    color: #4e4e4e;
    font-size: 15px;
    text-transform: uppercase;
    font-weight: 600;
    transition: all ease .5s;
}

header .dash-menu ul li a.active {
    color: #007ec5;
}

header .dash-menu ul li.active a, header .dash-menu ul li a:hover {
    color: #007ec5;
    transition: all ease .5s;
}

header.dashboard-header {
    padding: 20px 0px !important;
}

.react-datepicker-wrapper {
    display: block;
}

header.dashboard-header .head-info ul li.prof-opt .dropdown {
    position: relative;
    display: inline-block;
    top: 5px;
    left: 10px;
}

header.dashboard-header .head-info ul li.prof-opt a {
    font-size: 12px !important;
}

header.dashboard-header .head-info ul li.prof-opt .image-box {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    float: left;
}

header.dashboard-header .head-info ul li.prof-opt {
    padding: 5px 0 0;
}

.payment-form .form-check-inline .form-check-input {
    margin-top: 18px;
}

.payment-form .required {
    color: #b92a2a;
}


.payment-section .iHNqDx {
    padding: 20px;
}

.payment-section .beAHsZ {
    width: 100%;
    margin-bottom: 20px;
}


.payment-section .igzdhU {
    width: 50%;
}

.payment-section .kbeEM {
    width: 50%;
    padding-left: 0px;
}

.payment-section .cqQGRX {
    background-color: transparent;
    border: 1px solid #727377;
    border-radius: 4px;
    color: #000000;
    font-family: 'Poppins', sans-serif !important;
    font-size: 1em;
    height: 1em;
    padding: 1.5em;
    width: 100%;
    font-size: 16px;
}

.payment-section input {
    font-size: 16px;
    font-family: 'Poppins', sans-serif !important;
}

.payment-section .iXDzJJ {
    box-sizing: border-box;
    margin: 0;
    width: 100%;
}

.payment-section input, .payment-section .StripeElement {
    display: block;
    margin: 0px !important;
    max-width: 100%;
    padding: 10px 14px;
    font-size: 1em;
    font-family: 'Poppins', sans-serif !important;
    box-shadow: unset !important;
    border: 0;
    outline: 0;
    border-radius: 4px;
    background: white;
}

.payment-image {
    padding: 20px 20px 0px;
}

.payment-image h3 {
    display: inline-block;
}

.payment-image img {
    float: right;
}

.payment-section .gBytFC {
    width: 100%;
    padding: 0px;
}

.payment-section button {
    box-shadow: unset !important;
    background-color: #007abf !important;
    margin-top: 20px !important;
}

.payment-section .iTVrWH svg path {
    fill: #ff000099;
}

.payment-section .LpUaj {
    color: #ff000099;
}

.payment-section .jPkAPB {
    padding-top: 12px;
    padding-bottom: 12px;
}

.payment-section .fSWevF {
    background-color: transparent;
    border: 1px solid #727377;
    border-radius: 4px;
    color: #000000;
    font-family: 'Poppins', sans-serif !important;
    font-size: 1em;
    height: 1em;
    padding: 1.5em;
    width: 100%;
    font-size: 16px;
}

.payment-section .paymet-img {
    width: 100%;
    margin-top: -30px;
}

.payment-section .payment-details {
    padding-top: 30px;
}

.payment-section {
    padding-bottom: 30px;
}

.payment-section .spinner-loader .css-1ujetdd {
    margin: 0 auto;
    margin-top: 75px;
}