/* dashboard.css */
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i|Poppins:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&display=swap');

body, html {
    font-family: 'Poppins', sans-serif !important;
}

body {
    background: #fff;
}

.nopad {
    padding: 0;
}

.padleft {
    padding-left: 0;
}

.padright {
    padding-right: 0;
}

a:hover, a:active {
    text-decoration: none !important;
}

* :focus {
    outline: 0px auto -webkit-focus-ring-color !important;
    outline-offset: 0 !important;
    text-decoration: none !important;
}

* a:hover {
    text-decoration: none !important;
}

ul {
    padding: 0;
    margin: 0;
}

ul li {
    list-style: none;
    display: inline-block;
}

#return-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background: #ffbf3d;
    width: 50px;
    height: 50px;
    display: block;
    text-decoration: none;
    -webkit-border-radius: 35px;
    -moz-border-radius: 35px;
    border-radius: 35px;
    display: none;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    z-index: 9999;
    line-height: 50px;
    text-align: center;
    color: #fff;
    font-size: 25px;
}

#return-to-top:hover {
    background: rgba(0, 0, 0, 0.9);
}

#return-to-top:hover i {
    color: #fff;
    top: 5px;
}


/*Scroll css Start here*/


body::-webkit-scrollbar {
    width: 10px;
}

body::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 30px #000;
}

body::-webkit-scrollbar-thumb {
    background-color: #0576c2;
    outline: 1px solid #0576c2;
}

/*Scroll css End here*/

/*btn*/

a.btn-wt {
    color: #0069b2;
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    padding: 14px 40px;
    background: #fff;
    -webkit-box-shadow: 0px 4px 5px 1px rgba(87, 168, 215, 0.5);
    -moz-box-shadow: 0px 4px 5px 1px rgba(87, 168, 215, 0.5);
    box-shadow: 0px 4px 5px 1px rgba(87, 168, 215, 0.5);
    display: inline-block;
    transition: all ease-out .5s;
}

a.btn-wt:hover {
    background: #0069b2;
    color: #fff;
    transition: all ease-out .5s;
}

a.btn-bl {
    color: #fff;
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    padding: 14px 40px;
    background: #0069b2;
    -webkit-box-shadow: 0px 4px 5px 1px rgba(87, 168, 215, 0.5);
    -moz-box-shadow: 0px 4px 5px 1px rgba(87, 168, 215, 0.5);
    box-shadow: 0px 4px 5px 1px rgba(87, 168, 215, 0.5);
    display: inline-block;
    transition: all ease-out .5s;
}

a.btn-bl:hover {
    background: #fff;
    color: #0069b2;
    transition: all ease-out .5s;
}


.wd-95 {
    width: 95%;
    margin: 0 auto;
}

.pd-top-60 {
    padding-top: 60px;
}


/*header*/

header.dashboard-header {
    padding: 18px 0;
}

header .logo {

}

header .head-info {
    padding: 5px 0;
}

header .head-info ul {

}

header .head-info ul li {
    position: relative;
}

header .head-info ul li.notif {
    vertical-align: middle;
    margin-right: 30px;
}

header .head-info ul li.notif a {
    vertical-align: middle;
}

header .head-info ul li.notif a i {
    color: #77889a;
    font-size: 25px;
    vertical-align: middle;
}

header .head-info ul li.notif a span {
    position: absolute;
    background: #fa2c2c;
    width: 22px;
    height: 22px;
    border-radius: 100%;
    border: 2px solid #fff;
    color: #fff;
    text-align: center;
    right: -10px;
    font-size: 12px;
    top: -9px;
    font-weight: 500;
}

header .head-info ul li.prof-opt {

}

header .head-info ul li.prof-opt img {
    /*padding-right: 18px;*/
    vertical-align: middle;
}

header .head-info ul li.prof-opt a {
    color: white;
    font-weight: 400;
    font-size: 16px;
    vertical-align: middle;
}

.dropdown-content a {
    color: #000 !important;
}

header .head-info ul li.prof-opt a i {
    padding-left: 10px;
}

.dash-ban {
    background-size: 100%;
    background: #fff url("../images/dash-banner.jpg") no-repeat top;
}


/*prof-det*/

.prof-det {
    padding-bottom: 80px;
}

.prof-det .side {
    border: 2px solid #e1e2e4;
    border-top-left-radius: 5px;
    border-bottom: none;
    border-top-right-radius: 5px;
    background: #fff;
    padding: 25px 12px;
}

.prof-det .side .pic {
    text-align: center;
    position: relative;
    margin: 0 60px;
    width: 130px;
    height: 130px;
}

.prof-det .side .pic img {
    border-radius: 100%;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.prof-det .side .pic div.edit {
    position: absolute;
    right: -10px;
    bottom: 8%;
    width: auto;
}

.prof-det .side .pic div.edit i {
    color: #fff;
    font-size: 20px;
    border-radius: 100%;
    border: 4px solid #fff;
    background: #0069b2;
    padding: 8px;
}

.prof-det .side .det {
    margin: 30px 0;
}

.prof-det .side .det h2 {
    margin: 0;
    color: #4e4e4e;
    font-weight: 500;
    font-size: 23px;
    text-transform: capitalize;
}

.prof-det .side .det h5 {
    margin: 10px 0;
    color: #606060;
    font-weight: 300;
    font-size: 16px;
    text-transform: capitalize;
}

.prof-det .side .det p {
    margin: 0;
    color: #2c4059;
    font-size: 15px;
    font-weight: 300;
    vertical-align: middle;
}

.prof-det .side .det p i {
    padding-right: 10px;
    color: #2c4059;
    font-size: 18px;
    vertical-align: middle;
}

.prof-det .side .add-btn {
    margin: 25px 0 10px;
}

.prof-det .side .add-btn a {
    border: 1px solid #e1e1e1;
    padding: 17px 0;
    text-align: center;
    width: 100%;
    font-weight: 500;
    font-size: 16px;
    color: #5477f2;
    display: inline-block;
    border-radius: 5px;
    vertical-align: middle;
}

.prof-det .side .add-btn a:hover {
    background: #5477f2;
    color: #fff;
    transition: all ease .5s;
}

.prof-det .side .add-btn a i {
    padding-right: 10px;
    vertical-align: middle;
    font-size: 18px;
}

.prof-det .side-btm {
    border: 2px solid #e1e2e4;
    border-top: 1px solid #e1e2e4;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background: #fff;
    padding: 25px 0;
}

.prof-det .side-btm ul {
    text-align: center;
}

.prof-det .side-btm ul li {
    padding: 0px 10px;
}

.prof-det .side-btm ul li a {
    color: #4e4e4e;
    font-size: 13px;
    font-weight: 400;
}

.prof-det .main-area {
    border: 2px solid #e1e2e4;
    background: #fff;
    border-radius: 5px;
    background: #fff;
}

.prof-det .main-area .area-top {
    border-bottom: 1px solid #e5e8ec;
    padding: 0 40px;
}

.prof-det .main-area .area-top ul.nav {

}

.prof-det .main-area .area-top ul.nav li {

}

.prof-det .main-area .area-top ul.nav li:first-child {
    margin-right: 15px;
}

.prof-det .main-area .area-top ul.nav li a {
    color: #7b8b9d;
    font-size: 20px;
    font-weight: 400;
    padding: 30px 40px 25px 0px;
    display: block;
}

.prof-det .main-area .area-top ul.nav li a:hover, .prof-det .main-area .area-top ul.nav li a.active {
    color: #4e4e4e;
    font-size: 20px;
    border-bottom: 2px solid #5477f2;
}

.prof-det .main-area .area-btm {
    padding: 50px 40px;
    min-height: 775px;
}

.prof-det .main-area .area-btm .tab-content {

}

.prof-det .main-area .area-btm form label {
    color: #666666;
    font-weight: 400;
    font-size: 18px;
}

.prof-det .main-area .area-btm form input, .prof-det .main-area .area-btm form select, .prof-det .main-area .area-btm form textarea {
    width: 100%;
    border: 1px solid #e1e1e1;
    padding: 15px 22px;
    border-radius: 5px;
    margin-bottom: 22px;
    font-size: 17px;
    font-weight: 300;
    resize: none;
    color: #666666;
}


.contact-1 .box .lf input:focus, .contact-1 .box .lf textarea:focus, .contact-1 .box .lf select:focus {
    border: 1px solid #73beea;
}

.prof-det .main-area .area-btm form input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #666666;
}

.prof-det .main-area .area-btm form input::-moz-placeholder { /* Firefox 19+ */
    color: #666666;
}

.prof-det .main-area .area-btm form input:-ms-input-placeholder { /* IE 10+ */
    color: #666666;
}

.prof-det .main-area .area-btm form input:-moz-placeholder { /* Firefox 18- */
    color: #666666;
}

.prof-det .main-area .area-btm form select::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #666666;
}

.prof-det .main-area .area-btm form select::-moz-placeholder { /* Firefox 19+ */
    color: #666666;
}

.prof-det .main-area .area-btm form select:-ms-input-placeholder { /* IE 10+ */
    color: #666666;
}

.contact-1 .box .lf select:-moz-placeholder { /* Firefox 18- */
    color: #2c4059;
}

.prof-det .main-area .area-btm form textarea::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #666666;
}

.prof-det .main-area .area-btm form textarea::-moz-placeholder { /* Firefox 19+ */
    color: #666666;
}

.prof-det .main-area .area-btm form textarea:-ms-input-placeholder { /* IE 10+ */
    color: #666666;
}

.prof-det .main-area .area-btm form textarea:-moz-placeholder { /* Firefox 18- */
    color: #666666;
}


.prof-det .main-area .area-btm form input:focus, .prof-det .main-area .area-btm form select:focus, .prof-det .main-area .area-btm form textarea:focus {
    border: 1px solid #73beea;
}


.prof-det .main-area .area-btm form input[type="submit"] {
    color: #fff;
    text-transform: capitalize;
    font-size: 18px;
    font-weight: 500;
    width: auto;
    padding: 12px 50px;
    background: #5477f2;
    -webkit-box-shadow: 0px 4px 5px 1px rgba(87, 168, 215, 0.5);
    -moz-box-shadow: 0px 4px 5px 1px rgba(87, 168, 215, 0.5);
    box-shadow: 0px 4px 5px 1px rgba(87, 168, 215, 0.5);
    transition: all ease-out .5s;
    cursor: pointer;
}


.prof-det .main-area.add-mem {

}

.prof-det .main-area.add-mem h3 {
    margin: 0;
    color: #4e4e4e;
    font-weight: 500;
    font-size: 20px;
    position: relative;
    padding: 30px 30px 25px 0px;
    display: inline-block;
}

.prof-det .main-area.add-mem h3:before {
    content: '';
    position: absolute;
    background: #5477f2;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
}

.prof-det .main-area.add-mem .area-top {

}

.prof-det .main-area.add-mem .area-btm form a.addmore {
    color: #476be9;
    font-weight: 500;
    font-size: 14px;
    margin-left: 30px;
}

.prof-det .main-area.add-mem .area-btm table {
    margin: 50px 0 0;
    width: 100%;
    display: block;
}

.prof-det .main-area.add-mem .area-btm table thead {
    background: #f6f7ff;
    border-radius: 8px;
    width: 100%;
    /*display: block;*/
}

.prof-det .main-area.add-mem .area-btm table tbody {
    height: 300px;
    overflow-x: hidden;
    width: 100%;
    /*float: left;*/
}

.prof-det .main-area.add-mem .area-btm table tbody::-webkit-scrollbar {
    width: 5px;

}

.prof-det .main-area.add-mem .area-btm table tbody::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 30px #5477f2;
    border-radius: 10px;
}

.prof-det .main-area.add-mem .area-btm table tbody::-webkit-scrollbar-thumb {
    background-color: #f6f7ff;
    outline: 1px solid #f6f7ff;
    border-radius: 10px;
}

/*Scroll css End here*/
.prof-det .main-area.add-mem .area-btm table tbody tr {
    border-bottom: 1px solid #eff0f5;
}

.prof-det .main-area.add-mem .area-btm table tbody tr:last-child {
    border: none;
}

.prof-det .main-area.add-mem .area-btm table thead th {
    color: #333333;
    font-size: 17px;
    font-weight: 400;
    padding: 12px 30px;
}

.prof-det .main-area.add-mem .area-btm table td {
    color: #39414e;
    font-size: 15px;
    font-weight: 400;
    padding: 20px 15px 20px 30px;
}

.prof-det .main-area.add-mem .area-btm table td:first-child, .prof-det .main-area.add-mem .area-btm table th:first-child {
    width: 15%;
}

.prof-det .main-area.add-mem .area-btm table td:nth-child(2), .prof-det .main-area.add-mem .area-btm table th:nth-child(2) {
    width: 15%;
}

.prof-det .main-area.add-mem .area-btm table td:nth-child(3), .prof-det .main-area.add-mem .area-btm table th:nth-child(2) {
    width: 15%;
}

.prof-det .main-area.add-mem .area-btm table td:nth-child(4), .prof-det .main-area.add-mem .area-btm table th:nth-child(4) {
    width: 15%;
}

.prof-det .main-area.add-mem .area-btm table td:nth-child(5), .prof-det .main-area.add-mem .area-btm table th:nth-child(5) {
    width: 25%;
}

.prof-det .main-area.add-mem .area-btm table td:last-child, .prof-det .main-area.add-mem .area-btm table th:last-child {
    width: 15%;
}

.prof-det .main-area.add-mem .area-btm table td a {
    background: #f0f1fd;
    border-radius: 5px;
    color: #3751ff;
    font-size: 14px;
    font-weight: 400;
    padding: 8px;
}


/*search-1*/
body.search-dentist header {
    -webkit-box-shadow: 0px 0px 27px 8px rgba(0, 0, 0, 0.08);
    -moz-box-shadow: 0px 0px 27px 8px rgba(0, 0, 0, 0.08);
    box-shadow: 0px 0px 27px 8px rgba(0, 0, 0, 0.08);
    position: relative;
    z-index: 9;
}

.search-1 {
    overflow: hidden;
}

.search-1 .s-area {
    padding: 35px 0;
}

.search-1 .s-area .field {
    position: relative;
    margin: 0 40px 0 50px;
}

.search-1 .s-area .field:before {
    content: '';
    position: absolute;
    width: 23px;
    height: 23px;
    top: 15px;
    left: 25px;
    background: url(../images/search-icon.png) no-repeat;
}

.search-1 .s-area .field input[type="search"] {
    width: 100%;
    border: 1px solid #ebeef2;
    border-radius: 5px;
    background: #f5f7f9;
    color: #9ba9b5;
    font-size: 16px;
    font-weight: 300;
    padding: 14px 10px 12px 60px;
}

.search-1 .s-area .meta {
    margin: 25px 40px 10px 50px;
}

.search-1 .s-area .meta h4 {
    margin: 0;
    color: #4e4e4e;
    font-size: 23px;
    font-weight: 500;
    vertical-align: middle;
}

.search-1 .s-area .meta h4 span {
    color: #c7ceda;
    font-weight: 400;
    font-size: 20px;
    padding-left: 20px;
    vertical-align: middle;
}

.search-1 .s-area .s-det {
    height: calc(100vh - 280px);
    overflow-x: hidden;
    scroll-behavior: smooth;
    padding: 0 20px 0 50px;
    margin-right: 20px;
}

.search-1 .s-area .s-det::-webkit-scrollbar {
    width: 5px;

}

.search-1 .s-area .s-det::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 30px #5477f2;
    border-radius: 10px;
}

.search-1 .s-area .s-det::-webkit-scrollbar-thumb {
    background-color: #f6f7ff;
    outline: 1px solid #f6f7ff;
    border-radius: 10px;
    border-radius: 10px;
}

.search-1 .s-area .s-det .set {
    background: #fff;
    overflow: hidden;
    padding: 30px 20px;
    margin: 20px 0;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.08);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.08);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.08);
    border: 1px solid #eceff3;
    border-radius: 10px;
    transition: all ease .5s;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    cursor: pointer;
}

.search-1 .s-area .s-det .set:hover {
    box-shadow: 0px 0px 18px 4px rgba(0, 0, 0, 0.08);
    transition: all ease .5s;
    border-left: 5px solid #5577f2;
    border-right: 5px solid #5577f2;
}

.search-1 .s-area .s-det .set .icon {
    float: left;
    width: 15%;
    height: 15%;
    border-radius: 50%;
}

.search-1 .s-area .s-det .set .icon img {
    width: 100%;
    vertical-align: bottom;
    height: auto;
}

.search-1 .s-area .s-det .set .det {
    float: left;
    width: 85%
}

.search-1 .s-area .s-det .set .det p {
    display: block;
    color: #9a9a9a;
    font-size: 13px;
    font-weight: 300;
    margin: 0;
}

.search-1 .s-area .s-det .set .det h2 {
    margin: 0;
    color: #4e4e4e;
    font-weight: 500;
    font-size: 17px;
}

.search-1 .s-area .s-det .set .det h2 span.name {
    margin: 0;
    color: #4e4e4e;
    font-weight: 500;
    font-size: 17px;
    padding: 0 15px;
}

.search-1 .s-area .s-det .set .det h2 span.address {
    color: #c3c3c3;
    margin: 0;
    font-size: 13px;
    font-weight: 300;
}

.search-1 .m-area {
}

.search-1 .m-area iframe {
    height: calc(100vh - 100px);
}

.m-area iframe {
    border: 0;
}

.map-box iframe {
    border: 0;
}

.header-image {
    width: 80px;
    height: 50px;
}

.side-image {
    width: 10rem;
    height: 10rem;
}

.dropbtn {
    padding: 16px 0px;
    font-size: 16px;
    border: none;
    cursor: pointer;
    background: transparent;
    color: white;
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    text-align: left;
    top: 35px;
}

.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}


.dropdown:hover .dropdown-content {
    display: block;
}

.image-box {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    float: left;
}

.header-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}

.dropdown {
    position: relative;
    display: inline-block;
    top: 10px;
    left: 10px;
}

.dropdown i {
    color: #FFFFFF;
    padding-left: 10px;
}

header .head-info ul li .dropdown.dash-profile a {
    color: #000;
}

header .head-info ul li .dropdown.dash-profile i {
    color: #000;
}

.edit input[type="file"] {
    width: 45px;
    height: 45px;
    font-size: 0px;
    border: none;
    background: transparent;
    background-color: transparent;
    position: absolute;
    left: 0px;
    top: 0px;
    opacity: 0;
    cursor: pointer;
}

/*
.edit input[type="file"]:before {
    !*font-family: "Font Awesome 5 Free";*!
    content: "\f030";
    !*background-image: url("");*!
}*/
.carousel-caption {
    position: unset;
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #fff;
    text-align: center;
}

.carousel-inner {
    position: relative;
    width: 100%;
    overflow-y: visible;
    overflow-x: visible;
}

.test-image-box {
    margin: -130px auto 0px;
    width: 135px;
    height: 135px;
    text-align: center;
}

.test-image-box img {
    border-radius: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.carousel-caption p {
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    max-width: 80%;
    margin: 0 auto;
    line-height: 36px;
}

div.react-rater-star {
    font-size: 25px;
    background-color: #0576c2;
    width: 30px;
    height: 30px;
    line-height: 30px;
    border-radius: 4px;
    margin-right: 5px;
    color: #fff;
}

div.react-rater-star.is-active, div.react-rater-star.is-active-half:before {
    color: #f9d71c;
}

.rating {
    margin: 30px 0 18px;
}

.carousel-indicators {
    bottom: -40px;
}

.autocomplete-field {
    width: 95%;
    background: #fff;
    border: 1px solid #e1e2e4;
    border-radius: 7px;
    padding: 13px;
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 15px;
}

.submit {
    width: auto;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    text-transform: capitalize;
    padding: 14px 70px;
    background: #057cc5;
    box-shadow: 0px 4px 5px 1px rgba(87, 168, 215, 0.5);
    display: inline-block;
    transition: all ease-out .5s;
    cursor: pointer;
    margin: 10px 0;
    border-radius: 5px;
    border: none;
}

.modal_container.time_picker_modal_container#MaterialTheme {
    width: 120%;
}


.search-1 .s-area .field i.custom-spinner {
    position: absolute;
    top: 15px;
    right: -25px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}